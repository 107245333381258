import React from 'react';

import type { ModalContentProps } from './types';
import keyboard, { KEYS } from '~/utils/keyboard';

function ModalContent({ primaryAction, children }: ModalContentProps) {
  const handleKeyPress =
    primaryAction && primaryAction.onClick ? primaryAction.onClick : () => {};

  return (
    <div
      className="flex-auto pb-6 overflow-y-visible"
      onKeyPress={(e) => keyboard(e, KEYS.ENTER, handleKeyPress)}
    >
      {children}
    </div>
  );
}

export default ModalContent;

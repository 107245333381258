import React from 'react';

import Modal from '~/components/modal/Modal';
import BookForm from './BookForm';

import { useStateReducer } from '~/components/shared/hooks';
import type { Book } from './types';

type State = Omit<Book, 'id'>;
type Props = {
  onClose: () => void;
  addBook: (book: State) => void;
};
function AddBook({ onClose, addBook }: Props) {
  const [state, dispatch] = useStateReducer<State>({
    author: '',
    title: '',
    isFinished: false,
    isOwned: false,
    isReading: false,
    isDigital: false,
  });

  return (
    <Modal
      isOpen
      onClose={onClose}
      title="Add Book"
      primaryAction={{
        content: 'Add',
        onClick: () => addBook(state),
      }}
    >
      <BookForm
        {...state}
        onChange={(value, key) => dispatch({ type: key, value })}
      />
    </Modal>
  );
}

export default AddBook;

import React, { useEffect } from 'react';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';

import AuthService from '~/services/auth';

function AuthorizedRoute() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);

  useEffect(() => {
    if (AuthService.isAuthenticated()) {
      setIsAuthenticated(true);
    } else {
      navigate('/login', { state: { from: location } });
    }
  }, []);

  return isAuthenticated ? <Outlet /> : null;
}

export default AuthorizedRoute;

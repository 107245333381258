import React, { useRef, useState, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTable } from '@fortawesome/free-solid-svg-icons';

import { Button, Dropdown } from '~/components/shared/inputs';

import { upload } from '~/services/uploads';

const FILE_ID = 'media-csv';
const CSV_TYPES = [
  { value: 'book', text: 'Books' },
  { value: 'game', text: 'Games' },
];

function Uploads() {
  const inputRef = useRef<null | HTMLInputElement>(null);
  const [csvType, setCsvType] = useState('');
  const [fileToUpload, setFileToUpload] = useState<File | null>(null);
  const [successes, setSuccesses] = useState<number | null>(null);
  const [failures, setFailures] = useState<number | null>(null);
  const readerRef = useRef(new FileReader());
  const madeUpload = successes !== null && failures !== null;

  function handleFileChosen({ target }: React.ChangeEvent<HTMLInputElement>) {
    if (target && target.files && target.files.length === 1) {
      const file = target.files[0];
      setFileToUpload(file);
    }
  }

  function uploadFile() {
    readerRef.current.onload = async () => {
      const result = readerRef.current.result as string;
      const { successes, failures } = await upload(csvType, result);
      setSuccesses(successes);
      setFailures(failures);
      setFileToUpload(null);
    };

    readerRef.current.readAsText(fileToUpload as any);
  }

  return (
    <div className="h-full flex flex-col relative p-4">
      {madeUpload && (
        <div>
          <div>Added: {successes}</div>
          <div>Failed to Add: {failures}</div>
        </div>
      )}
      {fileToUpload && (
        <div className="center absolute text-center">
          <div>
            <FontAwesomeIcon icon={faTable} size="3x" />
          </div>
          <div>
            <label>{fileToUpload.name}</label>
          </div>
        </div>
      )}
      <div className="w-full flex flex-wrap justify-between">
        <div className="flex-auto flex-shrink-0">
          <Dropdown
            label="Media Type"
            items={CSV_TYPES}
            selectedValue={csvType}
            onChange={(value) => setCsvType(value)}
          />
        </div>
        {!!csvType && (
          <Fragment>
            <input
              id={FILE_ID}
              ref={inputRef}
              type="file"
              accept=".csv"
              className="hidden"
              onChange={handleFileChosen}
            />
            <label htmlFor={FILE_ID}>
              <Button onClick={() => inputRef.current?.click()}>
                Choose File
              </Button>
            </label>
            {fileToUpload && <Button onClick={uploadFile}>Upload</Button>}
          </Fragment>
        )}
      </div>
    </div>
  );
}

export default Uploads;

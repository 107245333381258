import React, { useReducer } from 'react';

import LoginRegistration from '~/components/LoginRegistration';
import AuthService from '~/services/auth';
import LocalStorage, { StorageKeys } from '~/services/localstorage';
import { useNavigate } from 'react-router';

type State = {
  authError: null | string;
  email: string;
  password: string;
};

type Action =
  | { type: 'authError'; error: string }
  | { type: 'email'; email: string }
  | { type: 'password'; password: string };

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'authError':
      return { ...state, authError: action.error };
    case 'email':
      return { ...state, email: action.email };
    case 'password':
      return { ...state, password: action.password };
    default:
      return state;
  }
}

function LoginPage() {
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, {
    email: '',
    password: '',
    authError: null,
  });
  const fields = [
    {
      placeholder: 'Enter Email',
      label: 'Email',
      value: state.email,
      type: 'email',
      handleChange: (email: string) => dispatch({ type: 'email', email }),
    },
    {
      placeholder: 'Enter Password',
      label: 'Password',
      value: state.password,
      type: 'password',
      handleChange: (password: string) =>
        dispatch({ type: 'password', password }),
    },
  ];

  async function handleLogin() {
    try {
      await AuthService.login(state.email, state.password);

      LocalStorage.set(StorageKeys.Email, state.email);

      navigate('/books');
    } catch (e) {
      const error = e as any;
      dispatch({ type: 'authError', error });
    }
  }

  return (
    <LoginRegistration
      title="MediaMan - Login"
      authError={!!state.authError}
      fields={fields}
      primaryActionText="Login"
      handlePrimaryAction={handleLogin}
      secondaryActionLink="/register"
      secondaryActionText="Need to sign up? Click Here!"
    />
  );
}

export default LoginPage;

import React, { useEffect, useRef, useState } from 'react';

import Portal from '~/components/shared/Portal';
import classNames from 'classnames';

type AnchorOriginType = {
  vertical: 'top' | 'center' | 'bottom';
  horizontal: 'left' | 'center' | 'right';
};
type Props = {
  anchorOrigin: AnchorOriginType;
  isOpen: boolean;
  autoHideDuration: number;
  message: string;
  onClose: () => void;
};

function Notification({
  anchorOrigin: { vertical, horizontal },
  isOpen,
  autoHideDuration,
  message,
  onClose,
}: Props) {
  const [shouldShow, setShouldShow] = useState(false);
  const timerRef = useRef<number | null>(null);

  useEffect(() => {
    if (isOpen && !shouldShow) {
      setShouldShow(true);
    } else if (!isOpen && shouldShow) {
      setShouldShow(false);
    }
  }, [isOpen, shouldShow]);

  useEffect(() => {
    if (autoHideDuration && shouldShow) {
      if (timerRef.current) {
        window.clearTimeout(timerRef.current);
      }

      timerRef.current = window.setTimeout(() => {
        setShouldShow(false);
        onClose();
      }, autoHideDuration);
    }

    return () => {
      if (timerRef.current) {
        window.clearTimeout(timerRef.current);
      }
    };
  }, [autoHideDuration, shouldShow]);

  return (
    <Portal>
      <div
        id="test"
        className={classNames(
          'fixed inset-0 bg-transparent pointer-events-none transition-transform transform',
          {
            'translate-y-full': !shouldShow,
            'translate-y-0': shouldShow,
            '-translate-y-full': vertical === 'top',
          },
        )}
      >
        <div
          className={classNames(
            'bg-primary text-white absolute p-2 pointer-events-auto shadow-inner',
            {
              'top-0': vertical === 'top',
              'top-1/2 transform -translate-y-1/2': vertical === 'center',
              'bottom-0': vertical === 'bottom',
              'left-0': horizontal === 'left',
              'left-1/2 transform -translate-x-1/2': horizontal === 'center',
              'right-0': horizontal === 'right',
            },
          )}
        >
          {message}
        </div>
      </div>
    </Portal>
  );
}

Notification.defaultProps = {
  onClose: () => {},
};

export default Notification;

import { ApolloQueryResult } from '@apollo/client';
import React, { Fragment } from 'react';
import { useLoaderData } from 'react-router';

import { Button } from '~/components/shared/inputs';
import { useIsLoading } from '~/hooks';

interface Friend {
  id: string;
  email: string;
}

function Friends() {
  const { data } = useLoaderData() as ApolloQueryResult<{
    friends: Array<Friend>;
  }>;
  const loading = useIsLoading();

  if (loading) return <Fragment>{'Loading...'}</Fragment>;
  if (data?.friends?.length === 0) return null;

  return (
    <div style={{ padding: '1rem' }}>
      <h3>Friends List</h3>
      {data &&
        data.friends.map((friend) => {
          return (
            <div key={friend.id}>
              <div style={{ margin: '1rem 0' }}>{friend.email}</div>
              <Button link={`/users/${friend.id}/media`}>View Games</Button>
            </div>
          );
        })}
    </div>
  );
}

export default Friends;

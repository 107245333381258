export const SMALL_WIDTH = '576px';
export const MEDIUM_WIDTH = '768px';
export const LARGE_WIDTH = '992px';
export const XLARGE_WIDTH = '1200px';

export default {
  SMALL: `(min-width: ${SMALL_WIDTH})`,
  MEDIUM: `(min-width: ${MEDIUM_WIDTH})`,
  LARGE: `(min-width: ${LARGE_WIDTH})`,
  XLARGE: `(min-width: ${XLARGE_WIDTH})`,
};

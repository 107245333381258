import React from 'react';

import Modal from '~/components/modal/Modal';
import MovieForm from './MovieForm';

import { useStateReducer } from '~/components/shared/hooks';
import type { AddMovieType } from './types';

type Props = {
  onClose: () => void;
  addMovie: (movie: AddMovieType) => void;
};

function AddMovie({ onClose, addMovie }: Props) {
  const [state, dispatch] = useStateReducer({
    title: '',
    rating: '',
    isWatched: false,
    isWantWatch: false,
  });

  return (
    <Modal
      isOpen
      onClose={onClose}
      title="Add Movie"
      primaryAction={{
        content: 'Add',
        onClick: () => addMovie(state),
      }}
    >
      <MovieForm
        {...state}
        onChange={(value, key) => dispatch({ type: key, value })}
      />
    </Modal>
  );
}

export default AddMovie;

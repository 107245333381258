import gql from 'graphql-tag';

export const ADMIN_USERS_QUERY = gql`
  query ($pageSize: Int = 10000) {
    users(first: $pageSize) {
      edges {
        node {
          id
          email
          isAdmin
        }
      }
    }
  }
`;

import gql from 'graphql-tag';

export const BOOKS_QUERY = gql`
  query {
    books {
      id
      title
      author
      isFinished
      isOwned
      isReading
      isDigital
    }
  }
`;

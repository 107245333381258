import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import Portal from '~/components/shared/Portal';
import { useKeyPress, useFocusStack } from '~/components/shared/hooks';
import type { ModalProps } from './types';
import ModalHeader from './ModalHeader';
import ModalContent from './ModalContent';
import ModalActions from './ModalActions';
import Overlay from '~/components/Overlay';

function Modal({
  isOpen,
  onClose,
  title,
  primaryAction,
  headerAction,
  children,
}: ModalProps) {
  const [isContentVisible, setIsContentVisible] = React.useState(isOpen);
  const modalRef = React.useRef<null | HTMLDivElement>(null);

  React.useEffect(() => {
    if (isOpen) {
      setIsContentVisible(true);
    }
  }, [isOpen]);

  const handleClose = () => {
    setIsContentVisible(false);
  };

  const handleOverlayClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    if (event.target === event.currentTarget) {
      handleClose();
    }
  };

  useKeyPress('Escape', handleClose);
  useFocusStack(modalRef);

  return (
    <Portal>
      <div className="relative z-10">
        {isOpen && (
          <Overlay onClick={handleOverlayClick}>
            <AnimatePresence onExitComplete={onClose}>
              {isContentVisible && (
                <motion.div
                  ref={modalRef}
                  key="modal"
                  className="min-w-[300px] w-full h-full md:w-auto md:h-auto flex flex-col pt-3 px-5 pb-2 outline-none rounded overflow-auto bg-white border border-solid border-white flex-initial"
                  initial={{ opacity: 0, y: '100%' }}
                  animate={{ opacity: 1, y: '0%' }}
                  exit={{ opacity: 0, y: '100%' }}
                  transition={{ duration: 0.3, type: 'tween' }}
                  role="dialog"
                  aria-label={title}
                  aria-modal="true"
                  tabIndex={-1}
                >
                  <ModalHeader
                    title={title}
                    action={headerAction}
                    onClose={handleClose}
                  />
                  <ModalContent primaryAction={primaryAction}>
                    {children}
                  </ModalContent>
                  <ModalActions primaryAction={primaryAction} />
                </motion.div>
              )}
            </AnimatePresence>
          </Overlay>
        )}
      </div>
    </Portal>
  );
}

export default Modal;

import gql from 'graphql-tag';

export const USER_PREFERENCES_QUERY = gql`
  query {
    userPreferences {
      id
      name
      value
    }
  }
`;
export const USER_HANDLES_QUERY = gql`
  query {
    userHandles {
      id
      name
      service
    }
  }
`;

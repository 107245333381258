export const KEYS = {
  ENTER: 'Enter',
  SPACE: ' ',
};

export default function (
  event: React.KeyboardEvent<HTMLElement>,
  key: string,
  action: () => void,
) {
  if (event.key === key && action) {
    action();

    event.preventDefault();
    event.stopPropagation();
  }
}

import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import gql from 'graphql-tag';

import { Dropdown, Toggle, Autocomplete } from '~/components/shared/inputs';

import type { GameReference, Platforms } from '../types';

const GAME_SEARCH_QUERY = gql`
  query ($searchTerm: String) {
    gameSearch(searchTerm: $searchTerm) {
      id
      title
      platforms
      date
      image
    }
  }
`;

function getDateValue(dateString: string | number) {
  const asNumber = Number(dateString);
  dateString = isNaN(asNumber) ? dateString : asNumber;
  if (typeof dateString === 'string') {
    const [year, monthName, day] = dateString.split('-');

    if (year && monthName && day) {
      return new Date(`${monthName} ${day}, ${year}`).toLocaleDateString();
    } else if (year && monthName) {
      //monthName here is the quarter
      return `${monthName} ${year}`;
    }

    return dateString;
  } else {
    return new Date(dateString * 1000).toLocaleDateString();
  }
}

type GameFormProps = {
  platforms: Platforms;
  title: string;
  platformId: string;
  isFinished: boolean;
  isOwned: boolean;
  isPlaying: boolean;
  isDigital: boolean;
  onChange: (value: string | boolean | null | undefined, key: string) => void;
};

function GameForm({
  platforms,
  title,
  platformId,
  isFinished = false,
  isOwned = false,
  isPlaying = false,
  isDigital = false,
  onChange,
}: GameFormProps) {
  const [selectedItem, setSelectedItem] = useState<null | GameReference>(null);
  const [searchGames, { data }] = useLazyQuery(GAME_SEARCH_QUERY);
  const hasSearchResult = data && !!title;
  const items: Array<GameReference> = hasSearchResult ? data.gameSearch : [];
  const validPlatforms = selectedItem
    ? platforms.filter((p) => selectedItem.platforms.includes(p.id))
    : platforms;

  function handleChange(value: string | null | undefined) {
    setSelectedItem(null);
    onChange(value, 'title');
  }

  function handleSelectedItem(value: GameReference) {
    setSelectedItem(value);
    onChange(value.title, 'title');
    onChange(value.id, 'igdbId');
  }

  useEffect(() => {
    if (title) {
      searchGames({ variables: { searchTerm: title } });
    }
  }, [title]);

  return (
    <div>
      <Autocomplete
        value={title}
        onTextChange={handleChange}
        onItemSelected={handleSelectedItem}
        label="Enter Game"
        labelKey="title"
        items={items}
        itemRender={(item: GameReference) => (
          <div className="flex" key={item.id}>
            <div className="w-10 h-10 flex border border-solid border-black">
              {item.image ? <img alt="Game Cover" src={item.image} /> : ''}
            </div>
            <div className="ml-4 flex justify-between flex-wrap">
              <div className="w-full">{item.title}</div>
              <div className="w-full">
                {item.date ? 'Release: ' + getDateValue(item.date) : 'N/A'}
              </div>
            </div>
          </div>
        )}
      />

      <Dropdown
        label="Platform"
        items={validPlatforms}
        valueKey="id"
        textKey="name"
        selectedValue={platformId}
        onChange={(value) => onChange(value, 'platformId')}
      />

      <Toggle
        checked={isFinished}
        onChange={(checked) => onChange(checked, 'isFinished')}
        label="Have you beat this game?"
      />

      <Toggle
        checked={isOwned}
        onChange={(checked) => onChange(checked, 'isOwned')}
        label="Do you own this game?"
      />

      {isOwned && (
        <Toggle
          checked={isDigital}
          onChange={(checked) => onChange(checked, 'isDigital')}
          label="Is this game digital?"
        />
      )}

      {isOwned && (
        <Toggle
          checked={isPlaying}
          onChange={(checked) => onChange(checked, 'isPlaying')}
          label="Are you currently playing this game?"
        />
      )}
    </div>
  );
}
export default GameForm;

import React, { useState } from 'react';
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';

import { Button, TextInput } from '~/components/shared/inputs';
import Paper from '~/components/shared/Paper';

import AuthService from '~/services/auth';

function PasswordReset() {
  const location = useLocation();
  const { resetId } = useParams<'resetId'>();
  const navigate = useNavigate();
  const searchMap = new URLSearchParams(location.search);
  const [email, setEmail] = useState(searchMap.get('email') || '');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');

  function sendPasswordResetRequest() {
    AuthService.sendPasswordResetRequest(email);
  }

  async function sendPasswordReset() {
    if (resetId) {
      await AuthService.sendPasswordReset(
        email,
        password,
        passwordConfirmation,
        resetId,
      );

      navigate('/login');
    }
  }

  return !resetId ? (
    <Paper className="max-w-xs w-full flex flex-col items-center justify-center pt-6 px-6">
      <h3 className="text-secondary">Password Reset Request</h3>
      <TextInput
        placeholder="Enter email"
        label="Email"
        value={email}
        type="email"
        onChange={setEmail}
      />
      <Button onClick={() => sendPasswordResetRequest()}>
        Click here to reset password
      </Button>
      <div className="py-4 cursor-pointer">
        <Link className="text-primary no-underline" to="/login">
          Click here to login
        </Link>
      </div>
    </Paper>
  ) : (
    <Paper className="max-w-xs w-full flex flex-col items-center justify-center pt-6 px-6">
      <h3 className="text-secondary">Password Reset</h3>
      <TextInput
        placeholder="Enter email"
        label="Email"
        value={email}
        type="email"
        onChange={setEmail}
      />
      <TextInput
        placeholder="Enter new password"
        label="New Password"
        value={password}
        type="password"
        onChange={setPassword}
      />
      <TextInput
        placeholder="Confirm new password"
        label="Confirm Password"
        value={passwordConfirmation}
        type="password"
        onChange={setPasswordConfirmation}
      />
      <Button onClick={sendPasswordReset}>Confirm</Button>
      <div className="py-4 cursor-pointer">
        <Link className="text-primary no-underline" to="/login">
          Click here to login
        </Link>
      </div>
    </Paper>
  );
}

export default PasswordReset;

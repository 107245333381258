import { INTEGRATIONS_URL } from '../config';
import AuthService from './auth';

const token = AuthService.getAuthToken();
const DEFAULT_OPTS: any = {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
  },
};

export async function importIntegration(type: 'steam') {
  const url = `${INTEGRATIONS_URL}/${type}`;
  const options = {
    ...DEFAULT_OPTS,
    headers: {
      ...DEFAULT_OPTS.headers,
    },
  };
  const assumeControl = AuthService.getAssumeControl();

  if (assumeControl) {
    options.headers.assconid = assumeControl.id;
  }

  const response = await fetch(url, options);
  const json = await response.json();

  return json.data;
}

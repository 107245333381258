import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import IconButton from '~/components/shared/inputs/IconButton';
import type { ModalHeaderProps } from './types';

function ModalHeader({ title, action, onClose }: ModalHeaderProps) {
  return (
    <div className="flex flex-auto flex-shrink-0 flex-grow-0 text-center justify-center m-0 pb-4">
      {!!action && (
        <IconButton
          a11yLabel="Delete"
          icon={action.content}
          className="absolute top-0 left-0"
          onClick={action.onClick}
        />
      )}
      <h2 className="block m-0 text-primary font-medium">{title}</h2>
      <IconButton
        a11yLabel="close"
        icon={<FontAwesomeIcon size="lg" icon={faTimes} />}
        className="absolute top-0 right-0"
        onClick={onClose}
      />
    </div>
  );
}

export default ModalHeader;

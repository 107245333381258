import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCogs,
  faUserPlus,
  faUserFriends,
  faLink,
  faCloudDownloadAlt,
  faCloudUploadAlt,
  faChevronLeft,
  faBook,
  faFilm,
  faGamepad,
  faTv,
  faHeadphones,
  faCrown,
} from '@fortawesome/free-solid-svg-icons';

import { getEmail } from '~/services/localstorage';
import Divider from '~/components/shared/Divider';
import IconButton from '~/components/shared/inputs/IconButton';
import Drawer, { DrawerContextValue } from '~/components/Drawer';
import AuthService from '~/services/auth';

type Props = {
  isDrawerOpen: boolean;
  onCloseDrawer: () => void;
};

function HeaderMenu({ isDrawerOpen, onCloseDrawer }: Props) {
  const isAdmin = AuthService.isAdmin();
  const headerItemClassName =
    'w-full flex relative text-left items-center py-4 py-3 px-6 no-underline justify-start hover:bg-white';
  const headerIconClassName = 'text-base text-primary w-6';
  const headerItemTextClassName = 'text-primary ml-4';

  return (
    <div>
      <Drawer anchor="left" isOpen={isDrawerOpen} onClose={onCloseDrawer}>
        <DrawerContextValue>
          {(onClose) => (
            <React.Fragment>
              <div className="text-right relative flex items-center justify-center pl-12">
                <div className="text-primary text-base">{getEmail()}</div>
                <IconButton
                  a11yLabel="Close"
                  icon={<FontAwesomeIcon size="lg" icon={faChevronLeft} />}
                  onClick={onClose}
                  className="ml-auto"
                />
              </div>
              <Divider />
              <div>
                <Link to="/books" onClick={onClose}>
                  <div className={headerItemClassName}>
                    <div className={headerIconClassName}>
                      <FontAwesomeIcon icon={faBook} size="lg" />
                    </div>
                    <div className={headerItemTextClassName}>Books</div>
                  </div>
                </Link>
                <Link to="/games" onClick={onClose}>
                  <div className={headerItemClassName}>
                    <div className={headerIconClassName}>
                      <FontAwesomeIcon icon={faGamepad} size="lg" />
                    </div>
                    <div className={headerItemTextClassName}>Games</div>
                  </div>
                </Link>
                <Link to="/movies" onClick={onClose}>
                  <div className={headerItemClassName}>
                    <div className={headerIconClassName}>
                      <FontAwesomeIcon icon={faFilm} size="lg" />
                    </div>
                    <div className={headerItemTextClassName}>Movies</div>
                  </div>
                </Link>
                <Link to="/shows" onClick={onClose}>
                  <div className={headerItemClassName}>
                    <div className={headerIconClassName}>
                      <FontAwesomeIcon icon={faTv} size="lg" />
                    </div>
                    <div className={headerItemTextClassName}>TV Shows</div>
                  </div>
                </Link>
                <Link to="/music" onClick={onClose}>
                  <div className={headerItemClassName}>
                    <div className={headerIconClassName}>
                      <FontAwesomeIcon icon={faHeadphones} size="lg" />
                    </div>
                    <div className={headerItemTextClassName}>Music</div>
                  </div>
                </Link>
              </div>
              <Divider />
              <Link to="/uploads" onClick={onClose}>
                <div className={headerItemClassName}>
                  <div className={headerIconClassName}>
                    <FontAwesomeIcon size="lg" icon={faCloudUploadAlt} />
                  </div>
                  <div className={headerItemTextClassName}>Upload CSV</div>
                </div>
              </Link>
              <Link to="/downloads" onClick={onClose}>
                <div className={headerItemClassName}>
                  <div className={headerIconClassName}>
                    <FontAwesomeIcon size="lg" icon={faCloudDownloadAlt} />
                  </div>
                  <div className={headerItemTextClassName}>Download CSV</div>
                </div>
              </Link>
              <Link to="/integrations" onClick={onClose}>
                <div className={headerItemClassName}>
                  <div className={headerIconClassName}>
                    <FontAwesomeIcon size="lg" icon={faLink} />
                  </div>
                  <div className={headerItemTextClassName}>Integrations</div>
                </div>
              </Link>
              <Link to="/invitations" onClick={onClose}>
                <div className={headerItemClassName}>
                  <div className={headerIconClassName}>
                    <FontAwesomeIcon size="lg" icon={faUserPlus} />
                  </div>
                  <div className={headerItemTextClassName}>Invitations</div>
                </div>
              </Link>
              <Link to="/friends" onClick={onClose}>
                <div className={headerItemClassName}>
                  <div className={headerIconClassName}>
                    <FontAwesomeIcon size="lg" icon={faUserFriends} />
                  </div>
                  <div className={headerItemTextClassName}>Friends</div>
                </div>
              </Link>
              <Link to="/settings" onClick={onClose}>
                <div className={headerItemClassName}>
                  <div className={headerIconClassName}>
                    <FontAwesomeIcon size="lg" icon={faCogs} />
                  </div>
                  <div className={headerItemTextClassName}>Settings</div>
                </div>
              </Link>
              {isAdmin && (
                <Link to="/admin" onClick={onClose}>
                  <div className={headerItemClassName}>
                    <div className={headerIconClassName}>
                      <FontAwesomeIcon size="lg" icon={faCrown} />
                    </div>
                    <div className={headerItemTextClassName}>Admin</div>
                  </div>
                </Link>
              )}
              <Divider />
              <div className="p-4 mx-auto">
                <Link className="text-primary" to="/logout">
                  Sign Out
                </Link>
              </div>
            </React.Fragment>
          )}
        </DrawerContextValue>
      </Drawer>
    </div>
  );
}

export default HeaderMenu;

import { AUTH_URL } from '../config';
import LocalStorage, { StorageKeys } from '~/services/localstorage';

const LOGIN_URL = `${AUTH_URL}/login`;
const LOGOUT_URL = `${AUTH_URL}/logout`;
const REGISTER_URL = `${AUTH_URL}/register`;
const PASSWORD_RESET_REQUEST_URL = `${AUTH_URL}/passwordResetRequest`;
const PASSWORD_RESET_URL = `${AUTH_URL}/passwordReset`;

const DEFAULT_OPTS = {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
};

type LoginResponse = { token: string; user_id: number };
async function login(email: string, password: string) {
  const options = {
    ...DEFAULT_OPTS,
    body: JSON.stringify({
      email,
      password,
    }),
  };

  const response = await fetch(LOGIN_URL, options);

  if (response.ok) {
    const token: LoginResponse = await response.json();
    setAuthToken(token.token);
  } else {
    clearAuthToken();
  }
}

async function logout() {
  const response = await fetch(LOGOUT_URL, DEFAULT_OPTS);

  if (response.ok) {
    clearAuthToken();
  }
}

function register(
  email: string,
  password: string,
  passwordConfirmation: string,
): Promise<{ token: string }> {
  const options = {
    ...DEFAULT_OPTS,
    body: JSON.stringify({
      email,
      password,
      passwordConfirmation,
    }),
  };

  return new Promise((resolve, reject) => {
    fetch(REGISTER_URL, options)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then(({ token }) => {
        setAuthToken(token);
        resolve(token);
      })
      .catch((error) => {
        clearAuthToken();
        reject(error);
      });
  });
}

function sendPasswordResetRequest(email: string): Promise<void> {
  const options = {
    ...DEFAULT_OPTS,
    body: JSON.stringify({
      email,
    }),
  };

  return new Promise((resolve, reject) => {
    fetch(PASSWORD_RESET_REQUEST_URL, options)
      .then((response) => {
        return response.ok ? resolve() : reject();
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function sendPasswordReset(
  email: string,
  password: string,
  passwordConfirmation: string,
  resetId: string,
) {
  const options = {
    ...DEFAULT_OPTS,
    body: JSON.stringify({
      email,
      password,
      password_confirmation: passwordConfirmation,
      reset_id: resetId,
    }),
  };

  return new Promise((resolve, reject) => {
    fetch(PASSWORD_RESET_URL, options)
      .then((response) => {
        return response.ok ? resolve(undefined) : reject();
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function isAuthenticated() {
  return !!getAuthToken();
}

function getAuthToken() {
  return LocalStorage.get(StorageKeys.Auth);
}

function setAuthToken(token: string) {
  return LocalStorage.set(StorageKeys.Auth, token);
}

function clearAuthToken() {
  return LocalStorage.remove(StorageKeys.Auth);
}

const tokenCache = new Map();
function getTokenPayload(token: string) {
  if (!tokenCache.has(token)) {
    const [, payload] = token.split('.');
    const safePayload = payload.replace(/-/g, '+').replace(/_/g, '/');
    const unicodeSafePayload = atob(safePayload)
      .split('')
      .map((c) => '%' + `00${c.charCodeAt(0).toString(16)}`.slice(-2))
      .join('');
    const stringPayload = decodeURIComponent(unicodeSafePayload);

    tokenCache.set(token, JSON.parse(stringPayload));
  }

  return tokenCache.get(token);
}

const adminClaim = 'adm';
function isAdmin() {
  const token = getAuthToken();
  const payload = token ? getTokenPayload(token) : {};

  return !!payload[adminClaim];
}

type AssumeControlType = { email: string; id: string };

function getAssumeControl(): AssumeControlType | undefined {
  const value = LocalStorage.get(StorageKeys.Admin);

  try {
    return value ? (JSON.parse(value) as AssumeControlType) : undefined;
  } catch (e) {
    console.error(e);
  }
}

function setAssumeControl(assumeControl: AssumeControlType) {
  const value = JSON.stringify(assumeControl);
  LocalStorage.set(StorageKeys.Admin, value);
}

function clearAssumeControl() {
  LocalStorage.remove(StorageKeys.Admin);
}

export default {
  login,
  logout,
  register,
  sendPasswordReset,
  sendPasswordResetRequest,
  isAuthenticated,
  getAuthToken,
  setAuthToken,
  getAssumeControl,
  setAssumeControl,
  clearAssumeControl,
  isAdmin,
  clearAuthToken,
};

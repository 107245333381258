import React from 'react';

import DesktopHeader from './DesktopHeader';
import MobileHeader from './MobileHeader';
import breakpoints from '~/utils/breakpoints';
import { useMatchMedia } from '~/components/shared/hooks';

export default function Header() {
  const hasMatches = useMatchMedia(breakpoints.MEDIUM);

  return hasMatches ? <DesktopHeader /> : <MobileHeader />;
}

import React, { useState } from 'react';

import { useMatchMedia, useAutoId } from '~/components/shared/hooks';
import breakpoints from '~/utils/breakpoints';
import classNames from 'classnames';

type Props = {
  type?: string;
  label: string;
  value: string;
  onChange: (value: string, event: React.ChangeEvent<HTMLInputElement>) => void;
  fullWidth?: boolean;
  autoFocus?: boolean;
  isWrapped?: boolean;
  placeholder?: string;
};

const TextInput = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      fullWidth,
      autoFocus,
      isWrapped,
      type,
      label,
      value,
      onChange,
    },
    inputRef,
  ) => {
    const [focus, setFocus] = useState(false);
    const isDesktop = useMatchMedia(breakpoints.MEDIUM);
    const id = `input-${useAutoId()}`;
    const shouldAutoFocus = autoFocus && isDesktop;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value, event);
    };

    return (
      <div
        className={classNames(
          'relative border border-solid border-primary p-2 my-1 mx-auto h-12 w-full rounded-sm',
          {
            'w-full': fullWidth,
            'border-2 border-solid border-primary': focus,
            'border-none w-full m-0 pt-0 -mt-1': isWrapped,
          },
        )}
      >
        <label
          htmlFor={id}
          className="absolute top-0.5 left-1.5 text-primary font-bold text-xs"
        >
          {label}
        </label>
        <div className="absolute bottom-0.5 left-2 right-2">
          <input
            id={id}
            ref={inputRef}
            type={type}
            className="bg-transparent border-none p-0 w-full text-primary focus:outline-none"
            autoFocus={shouldAutoFocus}
            value={value}
            onChange={handleChange}
            onBlur={() => setFocus(false)}
            onFocus={() => setFocus(true)}
          />
        </div>
      </div>
    );
  },
);

TextInput.defaultProps = {
  fullWidth: false,
  isWrapped: false,
  autoFocus: false,
  type: 'text',
};
TextInput.displayName = 'Text';

export default TextInput;

import React, { useState } from 'react';

export const SearchContext = React.createContext({
  searchValue: '',
  setSearchValue: (_searchValue: string) => {},
});

type Props = { children: React.ReactNode };
function SearchContextProvider({ children }: Props) {
  const [value, setValue] = useState('');

  function handleSearchChange(searchValue: string) {
    setValue(searchValue);
  }

  return (
    <SearchContext.Provider
      value={{ searchValue: value, setSearchValue: handleSearchChange }}
    >
      {children}
    </SearchContext.Provider>
  );
}

export default SearchContextProvider;

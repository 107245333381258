import React, { Fragment, useCallback, useMemo } from 'react';

import { useQuery, useMutation } from '@apollo/client';
import gql from 'graphql-tag';

import type { Game, Platforms, CheckoutStatuses, AddGameType } from './types';

const GAMES_PLATFORMS_QUERY = gql`
  query {
    checkoutStatuses {
      id
      label
    }
  }
`;

const ADD_GAME_MUTATION = gql`
  mutation addGame(
    $title: String!
    $platformId: String!
    $isFinished: Boolean!
    $isOwned: Boolean!
    $isPlaying: Boolean!
    $isDigital: Boolean!
    $igdbId: String
  ) {
    addGame(
      title: $title
      platformId: $platformId
      isFinished: $isFinished
      isOwned: $isOwned
      isPlaying: $isPlaying
      isDigital: $isDigital
      igdbId: $igdbId
    ) {
      id
      title
      platformId
      isFinished
      isOwned
      isPlaying
      isDigital
      checkoutStatusId
      borrowerId
      insertedAt
      coverUrl
    }
  }
`;

const UPDATE_GAME_MUTATION = gql`
  mutation updateGame(
    $id: ID!
    $title: String
    $platformId: String
    $isFinished: Boolean
    $isOwned: Boolean
    $isPlaying: Boolean
    $isDigital: Boolean
  ) {
    updateGame(
      id: $id
      title: $title
      platformId: $platformId
      isFinished: $isFinished
      isOwned: $isOwned
      isPlaying: $isPlaying
      isDigital: $isDigital
    ) {
      id
      title
      platformId
      isFinished
      isOwned
      isPlaying
      isDigital
    }
  }
`;

const DELETE_GAME_MUTATION = gql`
  mutation deleteGame($id: ID!) {
    deleteGame(id: $id) {
      id
      title
      platformId
    }
  }
`;

const REQUEST_CHECKOUT_MUTATION = gql`
  mutation requestCheckout(
    $ownerId: String!
    $mediaId: String!
    $mediaType: String!
  ) {
    requestCheckout(
      ownerId: $ownerId
      mediaId: $mediaId
      mediaType: $mediaType
    ) {
      id
      ownerId
      borrowerId
      checkout_statusId
      mediaId
      mediaType
    }
  }
`;

interface GamePlatformsQueryData {
  platforms: Platforms;
  games: Array<Game>;
  friendGames: Array<Game>;
  checkoutStatuses: CheckoutStatuses;
}

interface VideoGameDataRenderProps {
  isLoading: boolean;
  addGame: (game: AddGameType) => Promise<void>;
  editGame: (gameData: Game) => Promise<void>;
  deleteGame: (id: string) => Promise<void>;
  checkoutStatuses: CheckoutStatuses;
}

type Props = {
  selectedPlatformId: string;
  searchTerm: string;
  children: (renderProps: VideoGameDataRenderProps) => React.ReactNode;
};

export function useGameApi() {
  const [addGameMutation] = useMutation(ADD_GAME_MUTATION);
  const [updateGameMutation] = useMutation(UPDATE_GAME_MUTATION);
  const [deleteGameMutation] = useMutation(DELETE_GAME_MUTATION);

  const addGame = useCallback(async function addGame(game: AddGameType) {
    const {
      title,
      platformId,
      isFinished,
      isOwned,
      isPlaying,
      isDigital,
      igdbId,
    } = game;

    await addGameMutation({
      variables: {
        title,
        platformId,
        isFinished,
        isOwned,
        isPlaying,
        isDigital,
        igdbId,
      },
      update: (cache, { data: { addGame } }: any) => {
        cache.modify({
          fields: {
            games(existingGames = {}) {
              const newGameEdge = cache.writeFragment({
                data: { __typename: 'GameEdge', node: addGame },
                fragment: gql`
                  fragment NewGameEdge on GameEdge {
                    node {
                      id
                      title
                      platformId
                      isFinished
                      isOwned
                      isPlaying
                      isDigital
                      checkoutStatusId
                      borrowerId
                    }
                  }
                `,
              });
              const newGameConnection = {
                ...existingGames,
                edges: [...existingGames.edges, newGameEdge],
              };
              return newGameConnection;
            },
          },
        });
      },
    });
  }, []);

  const updateGame = useCallback(async function updateGame(data: Game) {
    const {
      id,
      title,
      platformId,
      isFinished = false,
      isOwned = false,
      isPlaying = false,
      isDigital = false,
    } = data;

    await updateGameMutation({
      variables: {
        id,
        title,
        platformId,
        isFinished,
        isOwned,
        isPlaying,
        isDigital,
      },
    });
  }, []);

  const deleteGame = useCallback(async function deleteGame(id: string) {
    const confirmation = window.confirm(
      'Are you sure you want to delete this game?',
    );

    if (confirmation) {
      await deleteGameMutation({
        variables: { id },
        update: (cache) => {
          cache.modify({
            fields: {
              games(existingGames, { readField }) {
                const result = existingGames.filter(
                  (f: any) => id !== readField('id', f),
                );
                return result;
              },
            },
          });
        },
      });
    }
  }, []);

  return useMemo(() => {
    return {
      addGame,
      updateGame,
      deleteGame,
    };
  }, [addGame, updateGame, deleteGame]);
}

export default function VideoGameData(props: Props) {
  const { loading, data } = useQuery<GamePlatformsQueryData>(
    GAMES_PLATFORMS_QUERY,
  );

  const { addGame, updateGame, deleteGame } = useGameApi();
  // const [requestCheckoutMutation] = useMutation(REQUEST_CHECKOUT_MUTATION);

  // function handleRequestCheckout(mediaId: number) {
  //   requestCheckoutMutation({
  //     variables: { ownerId: friendId, mediaId, mediaType: 'games' },
  //   });
  // }
  const isLoading = loading || !data;

  return (
    <Fragment>
      {props.children({
        isLoading,
        addGame,
        deleteGame,
        editGame: updateGame,
        checkoutStatuses: data?.checkoutStatuses ?? [],
      })}
    </Fragment>
  );
}

import React from 'react';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import Modal from '~/components/modal/Modal';
import MovieForm from './MovieForm';

import { useStateReducer } from '~/components/shared/hooks';
import type { Movie } from './types';

type Props = {
  onClose: () => void;
  onDelete: (id: string) => void;
  updateMovie: (movie: Movie) => void;
};

function EditMovie({ onClose, onDelete, updateMovie }: Props) {
  const location = useLocation();
  const movie = (location.state as any).movie;
  const [state, dispatch] = useStateReducer(movie);
  const headerAction = {
    content: <FontAwesomeIcon size="lg" icon={faTrashAlt} />,
    onClick: () => onDelete(state.id),
  };

  return (
    <Modal
      isOpen
      onClose={onClose}
      title="Update Movie"
      headerAction={headerAction}
      primaryAction={{
        content: 'Update',
        onClick: () => updateMovie(state),
      }}
    >
      <MovieForm
        {...state}
        onChange={(value, key) => dispatch({ type: key, value })}
      />
    </Modal>
  );
}

export default EditMovie;

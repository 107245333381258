import React, { useState, Fragment } from 'react';

import { Button, Dropdown, TextInput } from '~/components/shared/inputs';
import { download, DownloadType } from '~/services/downloads';

const CSV_TYPES = [
  { value: 'books', text: 'Books' },
  { value: 'games', text: 'Games' },
];

function Downloads() {
  const [filename, setFilename] = useState('');
  const [csvType, setCsvType] = useState<DownloadType | null>(null);

  function handleDownload() {
    if (csvType) {
      download(csvType, filename || 'media_backup');
    }
  }

  return (
    <div className="flex flex-col p-4 h-full">
      <Dropdown
        label="Media Type"
        items={CSV_TYPES}
        selectedValue={csvType}
        onChange={(value) => {
          if (value === 'books' || value === 'games') setCsvType(value);
        }}
      />
      {!!csvType && (
        <Fragment>
          <div>
            <TextInput
              autoFocus
              placeholder={`${csvType}_backup`}
              label="Enter filename"
              value={filename}
              type="text"
              onChange={setFilename}
            />
          </div>
          <div className="text-right w-full mt-auto">
            <Button onClick={handleDownload}>Download CSV</Button>
          </div>
        </Fragment>
      )}
    </div>
  );
}

export default Downloads;

import { FilterState } from '~/pages/VideoGames/types';
import { DEFAULT_FILTERS } from '~/pages/VideoGames/utils';

function dispatchChange() {
  const event = new Event('localStorageChange');
  window.dispatchEvent(event);
}

export enum StorageKeys {
  Email = 'email',
  Settings = 'settings',
  Auth = 'MEDIAMAN_AUTH_KEY',
  Admin = 'MEDIAMAN_ASSCON_ID',
  GameFilters = 'game_filters',
  GameBeta = 'game_beta',
}

const LocalStorage = {
  has: (key: string) => {
    return !!window.localStorage.getItem(key);
  },

  set: (key: string, value: any) => {
    window.localStorage.setItem(key, value);

    dispatchChange();
  },

  get: (key: string) => {
    return window.localStorage.getItem(key);
  },

  clear: () => {
    window.localStorage.clear();
    dispatchChange();
  },

  remove: (key: string) => {
    window.localStorage.removeItem(key);
    dispatchChange();
  },
};

export function getEmail() {
  return LocalStorage.get(StorageKeys.Email) || undefined;
}

export function getGameFilters(): FilterState {
  const gameFilters = LocalStorage.get(StorageKeys.GameFilters);
  const filterObj = gameFilters ? JSON.parse(gameFilters) : DEFAULT_FILTERS;

  if (filterObj.platforms) {
    const newPlatforms: Record<string, boolean> = {};
    for (const platformId in filterObj.platforms) {
      if (!Number.isInteger(parseInt(platformId, 10))) {
        newPlatforms[platformId] = filterObj.platforms[platformId];
      }
    }
    filterObj.platforms = newPlatforms;
  }
  if (filterObj.sort) {
    filterObj.sort = filterObj.sort.toUpperCase() === 'ASC' ? 'ASC' : 'DESC';
  }

  return filterObj;
}

export default LocalStorage;

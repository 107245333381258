import { useMemo } from 'react';
import type { FilterState, Game, GamesByPlatform, Platforms } from './types';

export const extraPlatforms: {
  [key: string]: { label: string; sort: number };
} = {
  recentlyAdded: { label: 'Recently Added', sort: -3 },
  beatGames: { label: 'Finished Games', sort: -2 },
  wishlistGames: { label: 'Wishlist', sort: -1 },
  playingGames: { label: 'Playing', sort: 0 },
};

export function getPlatformName(platforms: Platforms, platformId: string) {
  const platform = platforms.find((p) => p.id === platformId);

  function platformLookupFallback(platformKey: string) {
    return extraPlatforms[platformKey]
      ? extraPlatforms[platformKey].label
      : platformKey;
  }

  return platform ? platform.name : platformLookupFallback(platformId);
}

export const DEFAULT_FILTERS: FilterState = {
  isFinished: false,
  isOwned: true,
  isPlaying: false,
  isDigital: false,
  platforms: {},
  sort: 'ASC',
};

export function useGamesByPlatform(
  games: Array<Game>,
  searchValue = '',
): GamesByPlatform {
  return useMemo(() => {
    const gamesByPlatformInitial: GamesByPlatform = {
      beatGames: { data: [] },
      wishlistGames: { data: [] },
      playingGames: { data: [] },
      recentlyAdded: { data: [] },
    };
    const gamesByPlatform = games.reduce((gamesByPlatform, currentGame) => {
      const platformId = currentGame.platformId;
      const isGameBeat = !!currentGame.isFinished;
      const isPlayingGame = currentGame.isPlaying;
      const isGameWishlist = !currentGame.isOwned;
      const matchesSearch =
        currentGame.title.toLowerCase().indexOf(searchValue.toLowerCase()) !==
        -1;

      gamesByPlatform.recentlyAdded.data.push(currentGame);

      if (isGameBeat && matchesSearch) {
        gamesByPlatform.beatGames.data.push(currentGame);
      }

      if (isPlayingGame && matchesSearch) {
        gamesByPlatform.playingGames.data.push(currentGame);
      }

      if (isGameWishlist && matchesSearch) {
        gamesByPlatform.wishlistGames.data.push(currentGame);
      } else {
        if (!gamesByPlatform[platformId]) {
          gamesByPlatform[platformId] = {
            data: [],
            meta: { totalCompleted: 0, sortOrder: 3 },
          };
        }

        if (matchesSearch) {
          const platformGame = gamesByPlatform[platformId];
          platformGame.data.push(currentGame);

          if (isGameBeat && 'meta' in platformGame) {
            platformGame.meta.totalCompleted++;
          }
        }
      }

      return gamesByPlatform;
    }, gamesByPlatformInitial);

    Object.keys(gamesByPlatform).forEach((key) => {
      const gbp = gamesByPlatform[key];

      if (key === 'recentlyAdded') {
        gbp.data = gbp.data
          .sort(
            (g1, g2) =>
              new Date(g2.insertedAt).getTime() -
              new Date(g1.insertedAt).getTime(),
          )
          .slice(0, 10);
      }

      gbp.data.sort((g1, g2) => g1.title.localeCompare(g2.title));
    });

    return gamesByPlatform;
  }, [games, searchValue]);
}

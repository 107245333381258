import React, { useState } from 'react';
import { ApolloQueryResult, useMutation } from '@apollo/client';
import { useLoaderData } from 'react-router';
import { UserHandle } from '~/types';
import Modal from '~/components/modal/Modal';
import {
  Button,
  Dropdown,
  IconButton,
  TextInput,
} from '~/components/shared/inputs';
import {
  ADD_USER_HANDLE_MUTATION,
  UPDATE_USER_HANDLE_MUTATION,
  DELETE_USER_HANDLE_MUTATION,
} from '~/mutations';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faDeleteLeft, faE } from '@fortawesome/free-solid-svg-icons';
import PlaystationIcon from '~/components/PlaystationIcon';
import SteamIcon from '~/components/SteamIcon';
import ConditionalRender from '~/components/ConditionalRender';
import { USER_HANDLES_QUERY } from '~/queries';

type AddEditModalProps = {
  data:
    | {
        nameValue: string;
        platformId: string;
      }
    | {
        nameValue: undefined;
        platformId: undefined;
        availableHandles: UserHandleListItem[];
      };
  isOpen: boolean;
  onClose: () => void;
  onPrimary: (nameValue: string, platformId: string) => void;
};

function AddEditModal({ isOpen, onClose, onPrimary, data }: AddEditModalProps) {
  const [platformId, setPlatformId] = useState(data.platformId ?? '');
  const [nameValue, setNameValue] = useState(data.nameValue ?? '');
  const availableHandles =
    'availableHandles' in data ? data.availableHandles : null;
  const isAdd = !!availableHandles;
  const title = isAdd ? 'Add handle' : 'Edit handle';
  const primaryActionLabel = isAdd ? 'Add' : 'Edit';

  const handlePrimary = () => {
    onPrimary(nameValue, platformId);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      primaryAction={{
        content: primaryActionLabel,
        onClick: handlePrimary,
      }}
    >
      {availableHandles && (
        <Dropdown
          label="Platform"
          items={availableHandles}
          valueKey="id"
          textKey="id"
          selectedValue={platformId}
          onChange={setPlatformId}
        />
      )}
      <TextInput label="Name" value={nameValue} onChange={setNameValue} />
    </Modal>
  );
}

enum UserHandleTypes {
  Steam = 'Steam',
  PSN = 'PSN',
}
type UserHandleListItem = { id: UserHandleTypes };
const userHandleList: UserHandleListItem[] = [
  { id: UserHandleTypes.PSN },
  { id: UserHandleTypes.Steam },
];
const IconServiceMap: Record<string, any> = {
  [UserHandleTypes.PSN]: PlaystationIcon,
  [UserHandleTypes.Steam]: SteamIcon,
};

type Props = {
  id: string;
  service: string;
  name: string;
};
function UserHandleItem({ id, service, name }: Props) {
  const [updateUserHandleMutation] = useMutation(UPDATE_USER_HANDLE_MUTATION);
  const [deleteUserHandleMutation] = useMutation(DELETE_USER_HANDLE_MUTATION);
  const [isEdit, setIsEdit] = useState(false);
  const Icon = IconServiceMap[service];

  const handleDelete = async () => {
    const confirmation = window.confirm(
      `Are you sure you want to delete your ${service} handle?`,
    );

    if (confirmation) {
      await deleteUserHandleMutation({
        variables: { id },
      });
    }
  };
  const handleEdit = () => {
    setIsEdit(true);
  };
  const handleUpdateHandle = async (nameValue: string, platformId: string) => {
    await updateUserHandleMutation({
      variables: { id, name: nameValue, service: platformId },
    });

    setIsEdit(false);
  };

  return (
    <div className="flex items-center mb-4">
      <Icon />
      <label className="mx-4">{name}</label>
      <IconButton
        icon={<FontAwesomeIcon icon={faDeleteLeft} />}
        onClick={handleDelete}
        a11yLabel="Delete handle"
      />
      <IconButton
        icon={<FontAwesomeIcon icon={faEdit} />}
        onClick={handleEdit}
        a11yLabel="Edit handle"
      />
      {isEdit && (
        <AddEditModal
          isOpen
          onClose={() => setIsEdit(false)}
          onPrimary={handleUpdateHandle}
          data={{ nameValue: name, platformId: service }}
        />
      )}
    </div>
  );
}

type AddProps = {
  existingHandles: UserHandle[];
};
function AddUserHandle({ existingHandles }: AddProps) {
  const [isOpen, setIsOpen] = useState(false);

  const [addUserHandleMutation] = useMutation<
    { addUserHandleMutation: UserHandle },
    Omit<UserHandle, 'id'>
  >(ADD_USER_HANDLE_MUTATION);
  const availableHandles = userHandleList.filter((u) => {
    const handleExists = existingHandles.some((e) => e.service === u.id);

    return !handleExists;
  });

  function onClose() {
    setIsOpen(false);
  }

  async function handleAdd(nameValue: string, platformId: string) {
    await addUserHandleMutation({
      variables: {
        name: nameValue,
        service: platformId,
      },
    });

    setIsOpen(false);
  }
  const hasHandlesToAdd = availableHandles.length > 0;

  return (
    <>
      <ConditionalRender
        condition={hasHandlesToAdd}
        element={<Button onClick={() => setIsOpen((s) => !s)}>Add</Button>}
      />
      <ConditionalRender
        condition={hasHandlesToAdd && isOpen}
        element={
          <AddEditModal
            isOpen
            onClose={onClose}
            onPrimary={handleAdd}
            data={{
              nameValue: undefined,
              platformId: undefined,
              availableHandles,
            }}
          />
        }
      />
    </>
  );
}

function UserHandles() {
  const { data } = useLoaderData() as ApolloQueryResult<{
    userHandles: UserHandle[];
  }>;

  return (
    <>
      <div className="p-2">
        {data.userHandles.map(({ name, service, id }) => (
          <UserHandleItem key={id} service={service} name={name} id={id} />
        ))}
      </div>
      <AddUserHandle existingHandles={data.userHandles} />
    </>
  );
}

export default UserHandles;

import React, { useEffect, useState, Fragment } from 'react';
import {
  faSortAlphaDown,
  faSortAlphaUp,
} from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';

import type { FilterState, Game, Platform, Platforms } from './types';
import {
  Button,
  ButtonGroup,
  IconButton,
  Toggle,
} from '~/components/shared/inputs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MultiSelect from '~/components/shared/inputs/MultiSelect';
import classNames from 'classnames';

type Props = {
  state: FilterState;
  dispatch: any;
  platforms: Platforms;
  games: Game[];
};

function VideoGamesFilter({ state, dispatch, platforms, games }: Props) {
  const [showFilterMenu, setShowFilterMenu] = useState(false);

  const toggleSort = () => {
    dispatch({
      type: 'sort',
      value: state.sort === 'ASC' ? 'DESC' : 'ASC',
    });
  };

  const handleChange = (selectedItems: Platforms) => {
    const value: Record<string, boolean> = {};

    selectedItems.forEach((s) => {
      value[s.id] = true;
    });

    dispatch({
      type: 'platforms',
      value,
    });
  };
  const selectedValues = Object.keys(state.platforms);
  const handleFilterGroupChange = (property: string, newIndex: number) => {
    const value = newIndex === 0 ? false : newIndex === 1 ? true : undefined;
    dispatch({
      type: property,
      value,
    });
  };

  return (
    <div>
      <div className="flex items-center px-2">
        <IconButton
          className="ml-auto"
          a11yLabel="sort order"
          icon={
            state.sort === 'ASC' ? (
              <FontAwesomeIcon size="lg" icon={faSortAlphaUp} />
            ) : (
              <FontAwesomeIcon size="lg" icon={faSortAlphaDown} />
            )
          }
          onClick={toggleSort}
        />
        <Button autoWidth onClick={() => setShowFilterMenu((s) => !s)}>
          Filters {games.length ? ` (${games.length})` : ''}
        </Button>
      </div>
      <motion.div
        initial="hidden"
        animate={showFilterMenu ? 'visible' : 'hidden'}
        transition={{ type: 'tween' }}
        variants={{
          visible: {
            height: 'auto',
            opacity: 1,
            pointerEvents: 'all',
          },
          hidden: {
            height: 0,
            opacity: 0,
            pointerEvents: 'none',
          },
        }}
      >
        <div className="flex flex-wrap gap-4 items-center mb-4 px-2">
          <div className="w-full my-4 mx-2 mb-0">
            <MultiSelect<Platform>
              items={platforms}
              label="Systems"
              textKey="name"
              valueKey="id"
              selectedValues={selectedValues}
              onChange={handleChange}
            />
          </div>
          <ButtonGroup
            onChange={(newIndex) =>
              handleFilterGroupChange('isFinished', newIndex)
            }
            activeIndex={
              state.isFinished ? 1 : state.isFinished === undefined ? 2 : 0
            }
          >
            <Button index={0}>Unfinished</Button>
            <Button index={1}>Finished</Button>
            <Button index={2}>All</Button>
          </ButtonGroup>
          <ButtonGroup
            onChange={(newIndex) =>
              handleFilterGroupChange('isOwned', newIndex)
            }
            activeIndex={
              state.isOwned ? 1 : state.isOwned === undefined ? 2 : 0
            }
          >
            <Button index={0}>Unowned</Button>
            <Button index={1}>Owned</Button>
            <Button index={2}>All</Button>
          </ButtonGroup>
          <ButtonGroup
            onChange={(newIndex) =>
              handleFilterGroupChange('isPlaying', newIndex)
            }
            activeIndex={
              state.isPlaying ? 1 : state.isPlaying === undefined ? 2 : 0
            }
          >
            <Button index={0}>Not playing</Button>
            <Button index={1}>Playing</Button>
            <Button index={2}>All</Button>
          </ButtonGroup>
          <ButtonGroup
            onChange={(newIndex) =>
              handleFilterGroupChange('isDigital', newIndex)
            }
            activeIndex={
              state.isDigital ? 1 : state.isDigital === undefined ? 2 : 0
            }
          >
            <Button index={0}>Physical</Button>
            <Button index={1}>Digital</Button>
            <Button index={2}>All</Button>
          </ButtonGroup>
        </div>
      </motion.div>
    </div>
  );
}

function _VideoGamesFilter({ state, dispatch, platforms, games }: Props) {
  const [showFilterMenu, setShowFilterMenu] = useState(false);

  function toggleSort() {
    dispatch({
      type: 'sort',
      value: state.sort === 'ASC' ? 'DESC' : 'ASC',
    });
  }

  return (
    <Fragment>
      <div className="flex items-center py-0 px-4">
        <IconButton
          className="ml-auto"
          a11yLabel="sort order"
          icon={
            state.sort === 'ASC' ? (
              <FontAwesomeIcon size="lg" icon={faSortAlphaUp} />
            ) : (
              <FontAwesomeIcon size="lg" icon={faSortAlphaDown} />
            )
          }
          onClick={toggleSort}
        />
        <Button autoWidth onClick={() => setShowFilterMenu((s) => !s)}>
          Filters {games.length ? ` (${games.length})` : ''}
        </Button>
      </div>
      <motion.div
        initial="hidden"
        animate={showFilterMenu ? 'visible' : 'hidden'}
        transition={{ type: 'tween' }}
        variants={{
          visible: {
            height: 'auto',
            opacity: 1,
            pointerEvents: 'all',
          },
          hidden: {
            height: 0,
            opacity: 0,
            pointerEvents: 'none',
          },
        }}
      >
        <div className="filter-grid bg-white m-4 shadow-inner ">
          {platforms.map((p) => (
            <Toggle
              key={p.id}
              label={p.name}
              checked={!!state.platforms[p.id]}
              onChange={() =>
                dispatch({
                  type: 'platforms',
                  value: { ...state.platforms, [p.id]: !state.platforms[p.id] },
                })
              }
            />
          ))}
          <Toggle
            label="Is finished?"
            checked={state.isFinished}
            onChange={() =>
              dispatch({ type: 'isFinished', value: !state.isFinished })
            }
          />
          <Toggle
            label="Is owned?"
            checked={state.isOwned}
            onChange={() =>
              dispatch({ type: 'isOwned', value: !state.isOwned })
            }
          />
          <Toggle
            label="Is playing?"
            checked={state.isPlaying}
            onChange={() =>
              dispatch({ type: 'isPlaying', value: !state.isPlaying })
            }
          />
          <Toggle
            label="Is digital?"
            checked={state.isDigital}
            onChange={() =>
              dispatch({ type: 'isDigital', value: !state.isDigital })
            }
          />
        </div>
      </motion.div>
    </Fragment>
  );
}

export default VideoGamesFilter;

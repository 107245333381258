import { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

const body = document.querySelector('body');

type Props = {
  type?: 'modal' | 'dropdown';
  positioningRef?: React.RefObject<HTMLElement>;
  children: React.ReactNode;
};

export default function Portal({
  positioningRef,
  children,
  type = 'modal',
}: Props) {
  const ref = useRef(document.createElement('div'));
  const _positionRef = positioningRef || useRef();

  useEffect(() => {
    if (_positionRef.current) {
      const { top, left } = _positionRef.current.getBoundingClientRect();

      ref.current.style.top = `${top}px`;
      ref.current.style.left = `calc(${left}px - 0.5rem)`;
      ref.current.style.position = 'absolute';
      ref.current.style.background = 'white';
      ref.current.style.transform = 'translateY(27px)';
    }
  }, [_positionRef.current]);

  useEffect(() => {
    ref.current.style.position = 'relative';
    ref.current.style.zIndex = type === 'modal' ? '9001' : '9002';

    if (body) {
      body.appendChild(ref.current);
    }

    return () => {
      if (body) {
        body.removeChild(ref.current);
      }
    };
  }, []);

  return ReactDOM.createPortal(children, ref.current);
}

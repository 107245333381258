import React, { useRef, useCallback } from 'react';
import Downshift from 'downshift';
import { useDebouncedCallback } from 'use-debounce';

import ArrowIcon from '~/components/ArrowIcon';
import Portal from '~/components/shared/Portal';
import classNames from 'classnames';

function CloseIcon() {
  return (
    <svg
      viewBox="0 0 20 20"
      preserveAspectRatio="none"
      width={12}
      fill="transparent"
      stroke="#979797"
      strokeWidth="1.1px"
    >
      <path d="M1,1 L19,19" />
      <path d="M19,1 L1,19" />
    </svg>
  );
}

type ItemProps = { isActive: boolean; isSelected: boolean };
type ItemRenderType<Item> = (item: Item) => React.ReactNode;
type AutocompleteItemProps<Item> = {
  item: Item;
  itemRender: ItemRenderType<Item>;
  itemProps: ItemProps;
};
function AutocompleteItem<Item>({
  item,
  itemRender,
  itemProps,
}: AutocompleteItemProps<Item>) {
  const { isActive, isSelected, ...rest } = itemProps;

  return (
    <li
      {...rest}
      className={classNames(
        'relative cursor-pointer block border-0 h-auto text-left border-t-0 text-base',
        'transform-none font-normal shadow-none whitespace-normal py-3.5 px-5 text-black text-opacity-90',
        {
          'bg-opacity-5 bg-black text-black text-opacity-95': isActive,
          'font-bold text-black text-opacity-95': isSelected,
        },
      )}
    >
      {itemRender(item)}
    </li>
  );
}

type AutocompleteProps<Item> = {
  value: string | undefined;
  valueKey?: string;
  labelKey: string;
  label: string;
  placeholder: string;
  items: Array<Item>;
  itemRender: ItemRenderType<Item>;
  onTextChange: (value: string | null | undefined) => void;
  onItemSelected: (value: Item) => void;
};

function Autocomplete<Item>({
  label,
  labelKey,
  placeholder,
  items,
  itemRender,
  onTextChange,
  onItemSelected,
  value,
}: AutocompleteProps<Item>) {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const setInputRef = useCallback((node: HTMLInputElement) => {
    inputRef.current = node;
  }, []);

  const handleInputChange = useDebouncedCallback((changedValue: string) => {
    onTextChange(changedValue);
  }, 500);

  function handleInputValueChange(inputValueChange: string, changes: any) {
    if (changes.type === Downshift.stateChangeTypes.changeInput) {
      handleInputChange(inputValueChange || '');
    }
  }

  function handleSelect(selectedItem: any) {
    onItemSelected(selectedItem);
  }

  return (
    <Downshift
      initialInputValue={value}
      onInputValueChange={handleInputValueChange}
      onSelect={handleSelect}
      itemToString={(item) => (item ? item[labelKey] : item)}
    >
      {({
        getLabelProps,
        getInputProps,
        getToggleButtonProps,
        getMenuProps,
        getItemProps,
        getRootProps,
        isOpen,
        clearSelection,
        selectedItem,
        highlightedIndex,
      }) => (
        <div className="relative h-12 pt-2 px-2 my-1 mx-auto border border-solid border-primary">
          <label
            {...getLabelProps()}
            className="absolute top-1.5 left-1.5 text-primary font-bold text-xs"
          >
            {label}
          </label>
          <div
            className="absolute left-2 right-2 bottom-0.5"
            {...(getRootProps(undefined, { suppressRefError: true }) as any)}
          >
            <input
              ref={setInputRef}
              {...getInputProps({ placeholder })}
              className="bg-none border-none p-0 w-full text-primary"
            />
          </div>
          {selectedItem ? (
            <button onClick={() => clearSelection()} className="btn-icon">
              <CloseIcon />
            </button>
          ) : (
            <button {...getToggleButtonProps()} className="btn-icon">
              <div className="absolute bottom-0 right-2">
                <ArrowIcon isOpen={isOpen} />
              </div>
            </button>
          )}
          <Portal positioningRef={inputRef} type="dropdown">
            <ul
              {...getMenuProps({ open: isOpen })}
              className={classNames(
                'p-0 m-0 relative border border-solid border-primary bg-white rounded-b overflow-y-auto overflow-x-hidden outline-none transition-opacity max-h-80',
                {
                  'border-none': !isOpen,
                },
              )}
            >
              {isOpen
                ? items.map((item, index) => (
                    <AutocompleteItem
                      key={index}
                      item={item}
                      itemRender={itemRender}
                      itemProps={{
                        ...getItemProps({
                          item,
                          index,
                          isSelected: selectedItem === item,
                        }),
                        isActive: highlightedIndex === index,
                      }}
                    />
                  ))
                : null}
            </ul>
          </Portal>
        </div>
      )}
    </Downshift>
  );
}

Autocomplete.defaultProps = {
  label: '',
  placeholder: 'Enter text',
  onInputChange: () => {},
  items: [],
};

export default Autocomplete;

import React from 'react';

import breakpoints from '../utils/breakpoints';
import MobileNavigation from './MobileNavigation';
import { useMatchMedia } from './shared/hooks';

function Navigation() {
  const hasMatches = useMatchMedia(breakpoints.MEDIUM);

  return !hasMatches ? <MobileNavigation /> : null;
}

export default Navigation;

import React from 'react';
import { Link } from 'react-router-dom';

import classNames from 'classnames';

type Props = {
  children: any;
  secondary?: boolean;
  fullWidth?: boolean;
  autoWidth?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  link?: string;
  fab?: boolean;
  className?: string;
  index?: number;
};
function Button({
  children,
  secondary,
  fullWidth,
  autoWidth,
  onClick,
  link,
  fab,
  className: propClassName,
  index,
  ...rest
}: Props) {
  const className = classNames(propClassName, {
    btn: true,
    'btn-secondary': secondary,
    'btn-fab': fab,
    'w-full px-0 my-0 mx-auto': fullWidth,
    'w-auto': autoWidth,
  });
  const childrenNode = fab ? (
    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
      {children}
    </div>
  ) : (
    children
  );

  return link ? (
    <Link className={className} to={link} {...rest}>
      {childrenNode}
    </Link>
  ) : (
    <button
      className={className}
      onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        onClick && onClick(event);
      }}
      data-index={index}
      {...rest}
    >
      {childrenNode}
    </button>
  );
}

Button.defaultProps = {
  fullWidth: false,
};

export default Button;

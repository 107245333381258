import React from 'react';
import { createRoot } from 'react-dom/client';

import { injectTypography } from './utils/typography';
import { initTheme } from './components/shared/Theme';
import App from './App';
import './index.css';

import { ApolloProvider } from '@apollo/client';
import { client } from './graphql/index';

initTheme();
injectTypography();

export const MOUNT_NODE = document.getElementById('root');

const root = createRoot(MOUNT_NODE!);
root.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
);

import LocalStorage, { StorageKeys } from '~/services/localstorage';

export enum Feature {
  Card,
}

function getFeatureName(feature: Feature) {
  let name = '';

  switch (feature) {
    case Feature.Card: {
      name = 'card';
    }
  }

  return name;
}

export function isFeatureEnabled(feature: Feature) {
  const featureName = getFeatureName(feature);
  let enabled = false;

  switch (feature) {
    case Feature.Card: {
      const searchParams = new URLSearchParams(window.location.search);

      if (searchParams.has(featureName)) {
        enabled = searchParams.get(featureName) === 'true';
      }

      const isGameBeta = LocalStorage.get(StorageKeys.GameBeta);

      if (isGameBeta === 'true') {
        enabled = true;
      }
    }
  }

  return enabled;
}

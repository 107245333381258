import { gql } from '@apollo/client';

export const GAME_PLATFORMS_QUERY = gql`
  query {
    gamePlatforms {
      id
      name
    }
  }
`;

export const GAME_FILTER_QUERY = gql`
  query (
    $platformIds: [String]
    $showRecent: Boolean
    $isFinished: Boolean
    $isPlaying: Boolean
    $isWishlist: Boolean
    $isDigital: Boolean
    $searchTerm: String
    $sortDirection: SortDirection
    $pageSize: Int = 10000
  ) {
    games(
      first: $pageSize
      filter: {
        platformIds: $platformIds
        showRecent: $showRecent
        isFinished: $isFinished
        isPlaying: $isPlaying
        isWishlist: $isWishlist
        isDigital: $isDigital
        search: $searchTerm
      }
      sorts: [{ title: $sortDirection }]
    ) {
      edges {
        node {
          id
          title
          platformId
          isFinished
          isOwned
          isPlaying
          isDigital
          checkoutStatusId
          borrowerId
          insertedAt
          coverUrl
        }
      }
    }
  }
`;
export const FRIEND_GAMES_QUERY = gql`
  query (
    $friendId: String!
    $platformIds: [String]
    $showRecent: Boolean
    $isFinished: Boolean
    $isPlaying: Boolean
    $isWishlist: Boolean
    $sortDirection: SortDirection
    $pageSize: Int = 10000
  ) {
    platforms {
      id
      name
    }
    friendGames(
      first: $pageSize
      includeCheckouts: true
      friendId: $friendId
      filter: {
        platformIds: $platformIds
        showRecent: $showRecent
        isFinished: $isFinished
        isPlaying: $isPlaying
        isWishlist: $isWishlist
      }
      sorts: [{ title: $sortDirection }]
    ) {
      edges {
        node {
          id
          title
          platformId
          isFinished
          isOwned
          isPlaying
          isDigital
          checkoutStatusId
          borrowerId
        }
      }
    }
    checkoutStatuses {
      id
      label
    }
  }
`;

import React from 'react';

type Props = {
  label: string;
  icon?: JSX.Element;
};

function Chip({ label, icon }: Props) {
  return (
    <label className="rounded-full w-auto bg-primary text-white py-2 px-4 mx-4 flex items-center">
      {icon ? <span className="mr-2">{icon}</span> : null}
      {label}
    </label>
  );
}

export default Chip;

import classNames from 'classnames';
import React, { useState } from 'react';

import comingSoonReference from './coming_soon.jpg';
import type { Game } from '../types';
import { Link } from 'react-router-dom';
import { Button } from '~/components/shared/inputs';

type Props = {
  game: Game;
  platformName: string;
};
function BetaGameItem({ game, platformName }: Props) {
  const { title, isDigital, isFinished, isOwned, isPlaying, coverUrl } = game;
  const [isFlipped, setIsFlipped] = useState(false);

  return (
    <div
      style={{ perspective: '600px' }}
      className="w-full h-64"
      onClick={() => setIsFlipped((f) => !f)}
    >
      <div
        style={{
          transformStyle: 'preserve-3d',
          backfaceVisibility: isFlipped ? 'hidden' : 'visible',
          WebkitBackfaceVisibility: isFlipped ? 'hidden' : 'visible',
          transform: isFlipped ? 'rotateY(180deg)' : '',
        }}
        className={classNames({
          'w-full h-full relative cursor-pointer transition-transform ': true,
        })}
      >
        <div
          style={{
            backfaceVisibility: 'hidden',
            WebkitBackfaceVisibility: 'hidden',
          }}
          className="absolute h-full w-full"
        >
          <div
            style={{
              background: `url(${coverUrl ?? comingSoonReference})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
            }}
            className="h-3/4"
          />
          <div className="flex flex-col w-full h-1/4 p-2 bg-white">
            <label className="ellipsis-text" title={title}>
              {title}
            </label>
            <label className="ellipsis-text">{platformName}</label>
          </div>
        </div>
        <div
          style={{
            backfaceVisibility: 'hidden',
            transform: 'rotateY(180deg)',
            WebkitBackfaceVisibility: 'hidden',
          }}
          className="flex flex-col p-2 absolute w-full h-full bg-white text-black"
        >
          <label
            className="bg-primary text-white text-center -mx-2 -mt-2 p-2 shadow-lg ellipsis-text"
            title={title}
          >
            {title}
          </label>
          <p>Is Digital? {isDigital ? 'Yes' : 'No'}</p>
          <p>Is Finished? {isFinished ? 'Yes' : 'No'}</p>
          <p>Is Owned? {isOwned ? 'Yes' : 'No'}</p>
          <p>Is Playing? {isPlaying ? 'Yes' : 'No'}</p>
          <Button
            className="mt-auto"
            fullWidth
            onClick={(e) => e.stopPropagation()}
          >
            <Link
              className="w-full block"
              to={{
                pathname: `/games/${game.id}`,
              }}
              state={{ game }}
            >
              Edit
            </Link>
          </Button>
        </div>
      </div>
    </div>
  );
}

export default BetaGameItem;

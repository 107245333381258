import React from 'react';
import { Link } from 'react-router-dom';

import keyboard, { KEYS } from '~/utils/keyboard';

import { TextInput, Button } from '~/components/shared/inputs';
import Paper from '~/components/shared/Paper';

export interface FieldType {
  placeholder: string;
  label: string;
  value: string;
  type: string;
  handleChange: (email: string) => void;
}
type Props = {
  title: string;
  fields: FieldType[];
  authError: boolean;
  primaryActionText: string;
  handlePrimaryAction: () => void;
  secondaryActionLink: string;
  secondaryActionText: string;
};

function LoginRegistration({
  title,
  fields,
  authError,
  primaryActionText,
  handlePrimaryAction,
  secondaryActionLink,
  secondaryActionText,
}: Props) {
  return (
    <div className="h-full mx-8 relative">
      <Paper
        className="w-full flex flex-col items-center justify-center pt-6 px-6 max-w-sm h-3/4"
        onKeyPress={(e) => keyboard(e, KEYS.ENTER, handlePrimaryAction)}
      >
        <h3 className="text-secondary">{title}</h3>
        {!!authError && (
          <span className="text-red-600">Incorrect email/password</span>
        )}
        {fields &&
          fields.map((field, index) => (
            <TextInput
              key={index}
              fullWidth
              placeholder={field.placeholder}
              label={field.label}
              value={field.value}
              type={field.type}
              onChange={field.handleChange}
            />
          ))}
        <Button fullWidth onClick={handlePrimaryAction}>
          {primaryActionText}
        </Button>
        <div className="py-4 cursor-pointer">
          <Link className="text-primary" to={secondaryActionLink}>
            {secondaryActionText}
          </Link>
        </div>
        <div className="py-4 cursor-pointer">
          <Link className="text-primary" to="/reset">
            Forgot Password? Click here
          </Link>
        </div>
      </Paper>
    </div>
  );
}

LoginRegistration.defaultProps = {
  authError: false,
};

export default LoginRegistration;

import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import RaisedShadow from '~/components/shared/RaisedShadow';
import type { Book } from './types';
import { usePath } from '~/hooks';

type Props = {
  book: Book;
};

function BookItem({ book }: Props) {
  const { id, author, title, isFinished = false } = book;
  const path = usePath(id);

  return (
    <div className="flex-1 flex-shrink-0 max-w-full">
      <Link to={path} state={{ book }}>
        <RaisedShadow>
          <div>{author}</div>
          <div>{title}</div>
          {isFinished && (
            <div className="absolute top-2 right-2 text-primary">
              <FontAwesomeIcon size="lg" icon={faCheckCircle} />
            </div>
          )}
        </RaisedShadow>
      </Link>
    </div>
  );
}

export default BookItem;

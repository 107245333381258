import React from 'react';
import classNames from 'classnames';

export enum IconButtonColors {
  Inherit = 'inherit',
}
export enum IconPosition {
  Left = 'left',
  Right = 'right',
}

type Props = {
  a11yLabel: string;
  icon: JSX.Element;
  iconPosition?: IconPosition;
  color?: IconButtonColors;
  onClick: () => void;
  className?: string;
  children?: React.ReactNode;
};
const IconButton = React.forwardRef<HTMLButtonElement, Props>(
  (
    { a11yLabel, icon, iconPosition, color, onClick, className, children },
    ref,
  ) => {
    const shouldInherit = color === IconButtonColors.Inherit;
    const iconLeft = iconPosition === IconPosition.Left;
    const IconElement = React.cloneElement(icon, {
      className: 'hover:text-white',
    });
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();

      onClick();
    };

    return (
      <button
        ref={ref}
        className={classNames(
          className,
          'border-0 p-3 bg-transparent hover:text-white hover:cursor-pointer rounded',
          { 'text-current hover:bg-transparent': shouldInherit },
          { 'text-primary hover:bg-primaryFocus': !shouldInherit },
        )}
        onClick={handleClick}
        aria-label={a11yLabel}
      >
        <div
          className={classNames(
            'flex justify-center items-center hover:cursor-pointer',
            { 'text-current hover:bg-transparent': shouldInherit },
          )}
        >
          <React.Fragment>
            {iconLeft && IconElement}
            {children}
            {!iconLeft && IconElement}
          </React.Fragment>
        </div>
      </button>
    );
  },
);

IconButton.displayName = 'IconButton';
IconButton.defaultProps = {
  iconPosition: IconPosition.Left,
  className: {} as any,
};

export default IconButton;

import gql from 'graphql-tag';

export const MOVIES_QUERY = gql`
  query {
    movies {
      id
      title
      isWatched
      isWantWatch
      rating
      userId
    }
  }
`;

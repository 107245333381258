import React, { Fragment, useMemo } from 'react';
import { useQuery } from '@apollo/client';

import { useStateReducer } from '~/components/shared/hooks';
import { FRIEND_GAMES_QUERY } from './queries';
import { FilterState, GameConnection, Platforms } from './types';
import { DEFAULT_FILTERS } from './utils';
import VideoGamesFilter from './VideoGameFilters';
import GameList from './GameList';
import { useParams } from 'react-router';

export default function FriendGames() {
  const params = useParams();
  const [state, dispatch] = useStateReducer<FilterState>(DEFAULT_FILTERS);
  const platformIds = Object.keys(state.platforms).filter(
    (k) => state.platforms[k],
  );
  const { data } = useQuery<{
    friendGames: GameConnection;
    platforms: Platforms;
  }>(FRIEND_GAMES_QUERY, {
    variables: {
      friendId: params.id,
      platformIds,
      showRecent: false,
      isFinished: state.isFinished,
      isPlaying: state.isPlaying,
      isWishlist: !state.isOwned,
      sortDirection: state.sort,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });
  const games = useMemo(
    () => data?.friendGames.edges?.map((edge) => edge.node) ?? [],
    [data],
  );
  const platforms = data?.platforms ?? [];

  return (
    <Fragment>
      <VideoGamesFilter
        state={state}
        dispatch={dispatch}
        platforms={platforms}
        games={games}
      />
      <GameList
        state={state}
        dispatch={dispatch}
        platforms={platforms}
        games={games}
      />
    </Fragment>
  );
}

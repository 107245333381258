import { UPLOAD_URL } from '../config';
import AuthService from './auth';

const token = AuthService.getAuthToken();
const DEFAULT_OPTS = {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    authorization: `Bearer ${token}`,
  },
};

type UploadResponse = { successes: number; failures: number };
export function upload(
  type: string,
  fileData: string | ArrayBuffer,
): Promise<UploadResponse> {
  const url = `${UPLOAD_URL}/${type}s`;
  const options = {
    ...DEFAULT_OPTS,
    body: JSON.stringify({
      [`${type}_csv`]: fileData,
    }),
  };

  return new Promise((resolve, reject) => {
    fetch(url, options)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          reject();
        }
      })
      .then((json) => {
        resolve(json);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

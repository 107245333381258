import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFilm,
  faBook,
  faGamepad,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';

import classNames from 'classnames';

const indexNavMap: { [path: string]: number | undefined } = {
  shows: 0,
  games: 1,
  books: 2,
};

function getActiveIndex(pathname: string) {
  const path = pathname.replace('/', '');
  const pathIndex = indexNavMap[path] || 0;

  return pathIndex;
}

type NavItemProps = {
  isActive: boolean;
  label: string;
  icon: IconDefinition;
  onClick: () => void;
};
function NavItem({ isActive, icon, label, onClick }: NavItemProps) {
  return (
    <div className="flex-auto flex-shrink-0 pt-2 px-3 pb-2.5 bg-white flex flex-col items-center justify-center">
      <div onClick={onClick}>
        <FontAwesomeIcon
          icon={icon}
          size="2x"
          className={classNames({
            'text-primary': isActive,
            'text-accent': !isActive,
          })}
        />
      </div>
      <div
        className={classNames('mt-0.5 transition-transform', {
          'text-accent': !isActive,
          'text-primary transition-transform transform scale-125': isActive,
        })}
      >
        {label}
      </div>
    </div>
  );
}

function MobileNavigation() {
  const location = useLocation();
  const navigate = useNavigate();
  const activeIndex = getActiveIndex(location.pathname);

  function handleClick(path: string) {
    navigate(`/${path}`, { replace: true });
  }

  return (
    <footer className="nav-area shadow-md z-10 fixed bottom-0 left-0 right-0">
      <div className="p-0 flex items-center justify-center bg-white">
        <NavItem
          icon={faFilm}
          label="Movies"
          onClick={() => handleClick('movies')}
          isActive={activeIndex === 0}
        />
        <NavItem
          icon={faGamepad}
          label="Games"
          onClick={() => handleClick('games')}
          isActive={activeIndex === 1}
        />
        <NavItem
          icon={faBook}
          label="Books"
          onClick={() => handleClick('books')}
          isActive={activeIndex === 2}
        />
      </div>
    </footer>
  );
}

export default MobileNavigation;

import React, { useReducer } from 'react';

import LoginRegistration from '~/components/LoginRegistration';
import AuthService from '~/services/auth';
import LocalStorage, { StorageKeys } from '~/services/localstorage';
import { useNavigate } from 'react-router';

interface State {
  authError: any;
  email: string;
  password: string;
  passwordConfirmation: string;
}
type Action =
  | { type: 'authError'; error: any }
  | { type: 'email'; email: string }
  | { type: 'password'; password: string }
  | { type: 'passwordConfirmation'; passwordConfirmation: string };

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'authError':
      return { ...state, authError: action.error };
    case 'email':
      return { ...state, email: action.email };
    case 'password':
      return { ...state, password: action.password };
    case 'passwordConfirmation':
      return { ...state, passwordConfirmation: action.passwordConfirmation };
    default:
      return state;
  }
}

function LoginPage() {
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, {
    email: '',
    password: '',
    passwordConfirmation: '',
    authError: null,
  });
  const fields = [
    {
      placeholder: 'Enter Email',
      label: 'Email',
      value: state.email,
      type: 'email',
      handleChange: (email: string) => dispatch({ type: 'email', email }),
    },
    {
      placeholder: 'Enter Password',
      label: 'Password',
      value: state.password,
      type: 'password',
      handleChange: (password: string) =>
        dispatch({ type: 'password', password }),
    },
    {
      placeholder: 'Confirm Password',
      label: 'Confirm Password',
      value: state.passwordConfirmation,
      type: 'password',
      handleChange: (passwordConfirmation: string) =>
        dispatch({ type: 'passwordConfirmation', passwordConfirmation }),
    },
  ];

  async function handleRegistration() {
    try {
      await AuthService.register(
        state.email,
        state.password,
        state.passwordConfirmation,
      );

      LocalStorage.set(StorageKeys.Email, state.email);
      navigate('/');
    } catch (error) {
      dispatch({ type: 'authError', error });
    }
  }

  return (
    <LoginRegistration
      title="MediaMan - Signup"
      authError={state.authError}
      fields={fields}
      primaryActionText="Register"
      handlePrimaryAction={handleRegistration}
      secondaryActionLink="/login"
      secondaryActionText="Already have an account? Click Here!"
    />
  );
}

export default LoginPage;

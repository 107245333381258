import React, { useEffect, useRef, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch,
  faBell,
  faBars,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';

import { TextInput } from '~/components/shared/inputs';

import IconButton, {
  IconButtonColors,
} from '~/components/shared/inputs/IconButton';
import HeaderMenu from './HeaderMenu';
import NotificationMenu from './NotificationMenu';
import { motion } from 'framer-motion';
import { SearchContext } from '~/SearchContext';

function MobileHeader() {
  const { searchValue, setSearchValue } = useContext(SearchContext);
  const location = useLocation();
  const inputRef = useRef<null | HTMLInputElement>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [notificationMenuOpen, setNotificationMenuOpen] = useState(false);

  useEffect(() => {
    if (isSearchOpen && inputRef && inputRef.current) {
      inputRef.current.focus();
    }

    if (!isSearchOpen) {
      setSearchValue('');
    }
  }, [isSearchOpen]);

  useEffect(() => {
    setIsSearchOpen(false);
    setIsDrawerOpen(false);
  }, [location]);

  return (
    <header className="header-area flex justify-center items-center p-2 bg-primary text-white relative overflow-hidden text-sm">
      <HeaderMenu
        isDrawerOpen={isDrawerOpen}
        onCloseDrawer={() => setIsDrawerOpen(false)}
      />
      <IconButton
        a11yLabel="Menu"
        icon={<FontAwesomeIcon icon={faBars} />}
        color={IconButtonColors.Inherit}
        onClick={() => setIsDrawerOpen(true)}
        className="cursor-pointer"
      />
      <h2 className="absolute center tracking-widest header-title">MediaMan</h2>
      <IconButton
        a11yLabel="Notifications"
        icon={<FontAwesomeIcon icon={faBell} />}
        color={IconButtonColors.Inherit}
        onClick={() => setNotificationMenuOpen(!notificationMenuOpen)}
        className="ml-auto cursor-pointer"
      />
      <IconButton
        a11yLabel="Search"
        icon={<FontAwesomeIcon icon={faSearch} />}
        color={IconButtonColors.Inherit}
        onClick={() => setIsSearchOpen(true)}
        className="cursor-pointer"
      />

      <motion.div
        className="z-20 overflow-y-auto fixed top-12 left-0 right-0 bg-white text-primary mt-6 shadow-sm"
        initial="hidden"
        variants={{
          visible: { height: '100%', opacity: 1 },
          hidden: { height: 0, opacity: 0 },
        }}
        animate={notificationMenuOpen ? 'visible' : 'hidden'}
      >
        <NotificationMenu />
      </motion.div>

      <motion.div
        className="w-full absolute inset-x-0 top-0 h-full bg-white text-primary flex items-center shadow-inner"
        initial="hidden"
        transition={{ type: 'tween' }}
        variants={{
          visible: { x: '0%', opacity: 1 },
          hidden: { x: '100%', opacity: 0 },
        }}
        animate={isSearchOpen ? 'visible' : 'hidden'}
      >
        <IconButton
          a11yLabel="Search"
          icon={<FontAwesomeIcon icon={faSearch} />}
          className="text-primary"
          onClick={() => {}}
        />
        <TextInput
          ref={inputRef}
          label=""
          isWrapped
          autoFocus
          value={searchValue}
          onChange={setSearchValue}
        />

        <IconButton
          a11yLabel="Close"
          icon={<FontAwesomeIcon icon={faTimes} />}
          className="text-primary"
          onClick={() => setIsSearchOpen(false)}
        />
      </motion.div>
    </header>
  );
}

export default MobileHeader;

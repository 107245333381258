import classNames from 'classnames';
import React from 'react';

type Props = {
  showOnMobile?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  children: React.ReactNode;
};
function Overlay({ showOnMobile, onClick, children }: Props) {
  return (
    <div
      onClick={onClick}
      className={classNames(
        'fixed inset-0 flex justify-center items-center md:bg-black md:bg-opacity-50',
        {
          'bg-black bg-opacity-50': showOnMobile,
        },
      )}
    >
      {children}
    </div>
  );
}

export default Overlay;

import React from 'react';
import classNames from 'classnames';
type Props = {
  className?: false | string | Array<string>;
  children: React.ReactNode;
};

export default function RaisedShadow({ className, children }: Props) {
  return (
    <div
      className={classNames(
        'w-full text-primary bg-white shadow-md p-2 relative',
        className,
      )}
    >
      {children}
    </div>
  );
}

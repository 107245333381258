import React from 'react';

import Modal from '~/components/modal/Modal';
import GameForm from './GameForm';

import { useStateReducer } from '~/components/shared/hooks';
import type { Platforms, AddGameType } from '../types';

type Props = {
  onClose: () => void;
  platforms: Platforms;
  addGame: (gameState: AddGameType) => void;
};

function AddGame({ onClose, platforms, addGame }: Props) {
  const [state, dispatch] = useStateReducer({
    title: '',
    platformId: '',
    isFinished: false,
    isOwned: false,
    isPlaying: false,
    isDigital: false,
    igdbId: null,
  });

  return (
    <Modal
      isOpen
      onClose={onClose}
      title="Add Game"
      primaryAction={{
        content: 'Add',
        onClick: () => addGame(state),
      }}
    >
      <GameForm
        {...state}
        platforms={platforms}
        onChange={(value, key) => dispatch({ type: key, value })}
      />
    </Modal>
  );
}

export default AddGame;

import React, { useEffect, useState } from 'react';
import ConditionalRender from '~/components/ConditionalRender';
import { Button, Toggle } from '~/components/shared/inputs';
import {
  getTheme,
  generateTheme,
  setThemeVars,
} from '~/components/shared/Theme';
import LocalStorage, { StorageKeys } from '~/services/localstorage';
import UserHandles from './UserHandles';

function ThemeSetting() {
  const [, forceRender] = React.useReducer((a) => a + 1, 0);
  const [theme, setTheme] = useState(getTheme());

  useEffect(() => {
    setThemeVars(theme);
  }, [theme]);
  function generateNewTheme() {
    setTheme(generateTheme());
  }

  function saveColorTheme() {
    LocalStorage.set(StorageKeys.Settings, JSON.stringify(theme));
    forceRender();
  }

  function clearTheme() {
    LocalStorage.remove(StorageKeys.Settings);
    forceRender();
  }

  return (
    <>
      <Button autoWidth onClick={saveColorTheme}>
        Save Theme
      </Button>
      <Button autoWidth onClick={generateNewTheme}>
        New Theme
      </Button>
      <ConditionalRender
        condition={LocalStorage.has(StorageKeys.Settings)}
        element={<button onClick={clearTheme}>Clear Saved Theme</button>}
      />
    </>
  );
}

function SettingsPage() {
  const [isGameBeta, setIsGameBeta] = useState(() =>
    LocalStorage.get(StorageKeys.GameBeta) === 'true' ? true : false,
  );

  useEffect(() => {
    LocalStorage.set(StorageKeys.GameBeta, isGameBeta);
  }, [isGameBeta]);

  return (
    <div>
      <ThemeSetting />
      <Toggle
        checked={isGameBeta}
        onChange={(checked) => setIsGameBeta(checked)}
        label="Game beta"
      />
      <UserHandles />
    </div>
  );
}

export default SettingsPage;

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { Button } from '~/components/shared/inputs';
import { usePath } from '~/hooks';

type Props = { path: string };
function AddMediaButton({ path }: Props) {
  const link = usePath(path);
  return (
    <Button fab aria-label="add" link={link}>
      <FontAwesomeIcon icon={faPlus} />
    </Button>
  );
}

export default AddMediaButton;

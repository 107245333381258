import React from 'react';

import List from '~/components/shared/List';
import GameItem from './components/GameItem';
import { Feature, isFeatureEnabled } from '~/lib/Feature';
import BetaGameItem from './components/BetaGameItem';
import { FilterState, Game, Platforms } from './types';
import { getPlatformName } from './utils';

type Props = {
  state: FilterState;
  dispatch: any;
  platforms: Platforms;
  games: Game[];
};

function GameList({ games, platforms }: Props) {
  return isFeatureEnabled(Feature.Card) ? (
    <ul className="game-grid">
      {games.map((game) => (
        <BetaGameItem
          key={game.id}
          game={game}
          platformName={getPlatformName(platforms, game.platformId.toString())}
        />
      ))}
    </ul>
  ) : (
    <List
      items={games}
      itemRender={(game: Game) => (
        <GameItem
          key={game.id}
          game={game}
          isReadOnly={false}
          checkoutStatuses={[]}
          platformName={getPlatformName(platforms, game.platformId.toString())}
        />
      )}
    />
  );
}

export default GameList;

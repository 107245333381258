import React from 'react';

import { Button } from '~/components/shared/inputs';
import type { ModalActionProps } from './types';

function ModalActions({ primaryAction }: ModalActionProps) {
  return primaryAction ? (
    <Button onClick={primaryAction.onClick}>{primaryAction.content}</Button>
  ) : null;
}

export default ModalActions;

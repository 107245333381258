import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  from,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { relayStylePagination } from '@apollo/client/utilities';

import { GRAPHQL_URL } from '../config';
import AuthService from '../services/auth';

const httpLink = new HttpLink({ uri: GRAPHQL_URL });
const logoutLink = onError(({ networkError, graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );
  }

  if (
    networkError &&
    'statusCode' in networkError &&
    networkError.statusCode === 401
  ) {
    AuthService.logout().then(() => {
      window.location.pathname = '/login';
    });
  }
});
const authMiddleware = new ApolloLink((operation, forward) => {
  // get the authentication token from local storage if it exists
  const token = AuthService.getAuthToken();
  const assumeControl = AuthService.getAssumeControl();
  const headers = {
    authorization: `Bearer ${token}`,
    assconid: assumeControl ? assumeControl.id : undefined,
  };

  operation.setContext({
    headers,
  });

  return forward(operation);
});

export function createClient() {
  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          games: relayStylePagination(),
        },
      },
      GameEdge: {
        keyFields: ['node'],
      },
    },
  });

  return new ApolloClient({
    cache,
    link: from([authMiddleware, logoutLink, httpLink]),
    resolvers: {
      Mutation: {
        updateNetworkStatus: (_, { isConnected }, { cache }) => {
          cache.writeData({ data: { isConnected } });
          return null;
        },
      },
    },
  });
}

export const client = createClient();

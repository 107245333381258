import React from 'react';
import { Route, useNavigate, Routes, useLoaderData } from 'react-router-dom';
import { ApolloQueryResult, useMutation } from '@apollo/client';
import gql from 'graphql-tag';

import { useConfirmation } from '~/components/shared/hooks';
import AddMediaButton from '~/components/shared/AddMediaButton';
import List from '~/components/shared/List';
import MovieItem from './components/MovieItem';
import AddMovie from './components/AddMovie';
import EditMovie from './components/EditMovie';
import type { Movie } from './components/types';
import { MOVIES_QUERY } from './queries';
import { useIsLoading } from '~/hooks';

const ADD_MOVIE_MUTATION = gql`
  mutation addMovie(
    $title: String!
    $rating: String!
    $isWatched: Boolean!
    $isWantWatch: Boolean!
  ) {
    addMovie(
      title: $title
      rating: $rating
      isWatched: $isWatched
      isWantWatch: $isWantWatch
    ) {
      id
      title
      isWatched
      isWantWatch
      rating
      userId
    }
  }
`;

const UPDATE_MOVIE_MUTATION = gql`
  mutation updateMovie(
    $id: ID!
    $title: String!
    $rating: String!
    $isWatched: Boolean!
    $isWantWatch: Boolean!
  ) {
    updateMovie(
      id: $id
      title: $title
      rating: $rating
      isWatched: $isWatched
      isWantWatch: $isWantWatch
    ) {
      id
      title
      isWatched
      isWantWatch
      rating
      userId
    }
  }
`;
const DELETE_MOVIE_MUTATION = gql`
  mutation deleteMovie($id: ID!) {
    deleteMovie(id: $id) {
      id
      title
      isWatched
      isWantWatch
      rating
      userId
    }
  }
`;

function Movies() {
  const navigate = useNavigate();
  const { data } = useLoaderData() as ApolloQueryResult<{
    movies: Array<Movie>;
  }>;
  const loading = useIsLoading();
  const [addMovieMutation] = useMutation(ADD_MOVIE_MUTATION);
  const [deleteMovieMutation] = useMutation(DELETE_MOVIE_MUTATION);
  const [updateMovieMutation] = useMutation(UPDATE_MOVIE_MUTATION);

  function handleClose() {
    navigate('.');
  }

  function handleDelete(id: string) {
    const [confirmation] = useConfirmation();

    if (confirmation) {
      deleteMovieMutation({
        variables: { id },
        update: (proxy, { data: { deleteMovie } }) => {
          const data = proxy.readQuery<{ movies: Movie[] }>({
            query: MOVIES_QUERY,
          });

          if (data) {
            data.movies = data.movies.filter((b) => b.id !== deleteMovie.id);
            proxy.writeQuery({ query: MOVIES_QUERY, data });
          }
        },
      }).then(handleClose);
    }
  }

  function addMovie(data: Partial<Movie>) {
    const { title, rating = '', isWatched, isWantWatch } = data;

    addMovieMutation({
      variables: { title, rating, isWatched, isWantWatch },
      update: (proxy, { data: { addMovie } }) => {
        const queryData = proxy.readQuery<{ movies: Movie[] }>({
          query: MOVIES_QUERY,
        });

        if (queryData) {
          queryData.movies.push(addMovie);
          proxy.writeQuery({ query: MOVIES_QUERY, data: queryData });
        }
      },
    }).then(handleClose);
  }

  function updateMovie(data: Partial<Movie>) {
    const { id, title, rating, isWatched, isWantWatch } = data;

    updateMovieMutation({
      variables: { id, title, rating, isWatched, isWantWatch },
    }).then(handleClose);
  }

  if (loading) {
    return <React.Fragment>Loading...</React.Fragment>;
  }

  let movies: Movie[] = [];
  if (data) {
    movies = data.movies;
  }

  return (
    <div className="ios-scroll overflow-y-auto max-page-height">
      <List
        items={movies}
        itemRender={(movie) => (
          <MovieItem key={movie.id} movie={movie} updateMoviePath=":id" />
        )}
        noResults={() => 'You have no movies here'}
      />
      <AddMediaButton path="add" />
      <Routes>
        <Route
          path="add"
          element={
            <AddMovie
              addMovie={(data) => addMovie(data)}
              onClose={handleClose}
            />
          }
        />
        <Route
          path=":id"
          element={
            <EditMovie
              updateMovie={(data) => updateMovie(data)}
              onDelete={(id) => handleDelete(id)}
              onClose={handleClose}
            />
          }
        />
      </Routes>
    </div>
  );
}

export default Movies;

import { DOWNLOAD_URL } from '../config';
import AuthService from './auth';
import downloadFile from 'downloadjs';

const token = AuthService.getAuthToken();
const DEFAULT_OPTS = {
  method: 'GET',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    authorization: `Bearer ${token}`,
  },
};

export type DownloadType = 'books' | 'games';
export function download(
  type: DownloadType,
  filename = 'backup.csv',
  fromServer = false,
) {
  const url = `${DOWNLOAD_URL}/${type}`;
  const options = {
    ...DEFAULT_OPTS,
  };

  return new Promise((resolve, reject) => {
    if (fromServer) {
      window.open(`${url}?filename=${filename}`, '_blank');
      resolve(undefined);
    } else {
      if (!filename.endsWith('.csv')) {
        filename = filename + '.csv';
      }

      fetch(url, options)
        .then((response) => {
          if (response.ok) {
            return response.blob();
          } else {
            reject();
          }
        })
        .then((blob) => {
          if (blob) {
            downloadFile(blob, filename, 'text/csv');
          }
          resolve(undefined);
        })
        .catch((error) => {
          reject(error);
        });
    }
  });
}

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faClock } from '@fortawesome/free-solid-svg-icons';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';

import Modal from '~/components/modal/Modal';
import GameForm from './GameForm';
import { useStateReducer } from '~/components/shared/hooks';

import type { Game, GameTime, Platforms } from '../types';
import { useLocation } from 'react-router';
import Chip from '~/components/shared/Chip';
import ChipGroup from '~/components/shared/ChipGroup';

const GAME_TIMES_QUERY = gql`
  query ($title: String!) {
    gameTimes(title: $title) {
      label
      time
    }
  }
`;

type GameTimeProps = { title: string };
function GameTimes({ title }: GameTimeProps) {
  const { loading, error, data } = useQuery(GAME_TIMES_QUERY, {
    variables: { title: title },
  });
  const shouldRender = !error && data && data.gameTimes;
  const gameTimes = data && data.gameTimes;

  return !error ? (
    <ChipGroup className="m-0 w-auto sm:w-96">
      {loading
        ? 'Loading...'
        : shouldRender
        ? gameTimes.map((gameTime: GameTime, index: number) => (
            <Chip
              key={index}
              label={`${gameTime.label}: ${gameTime.time}`}
              icon={<FontAwesomeIcon icon={faClock} />}
            />
          ))
        : null}
    </ChipGroup>
  ) : null;
}

type EditGameProps = {
  onClose: () => void;
  onDelete: (id: string) => void;
  updateGame: (game: Game) => void;
  platforms: Platforms;
};
function EditGame({ onClose, onDelete, updateGame, platforms }: EditGameProps) {
  const location = useLocation();
  const game = (location.state as any).game as Game;
  const [state, dispatch] = useStateReducer(game);

  return (
    <Modal
      isOpen
      onClose={onClose}
      title="Update Game"
      headerAction={{
        content: <FontAwesomeIcon size="lg" icon={faTrashAlt} />,
        onClick: () => state.id && onDelete(state.id),
      }}
      primaryAction={{
        content: 'Update',
        onClick: () => updateGame(state),
      }}
    >
      <GameTimes title={game.title} />
      <GameForm
        {...state}
        platforms={platforms}
        onChange={(value, key) => dispatch({ type: key, value })}
      />
    </Modal>
  );
}

export default EditGame;

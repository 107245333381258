import gql from 'graphql-tag';

export const FRIENDS_QUERY = gql`
  query {
    friends {
      id
      email
    }
  }
`;

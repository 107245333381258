import {
  useState,
  useLayoutEffect,
  useEffect,
  useRef,
  useReducer,
} from 'react';
import throttle from 'lodash-es/throttle';
import json2mq from 'json2mq';
import AuthService from '~/services/auth';

export function useFocusStack(
  ref: React.MutableRefObject<HTMLElement | null | undefined>,
) {
  const currentFocusedElement = useRef(window.document.activeElement);

  useEffect(
    () => () => {
      if (
        currentFocusedElement.current &&
        currentFocusedElement.current instanceof HTMLElement
      ) {
        currentFocusedElement.current.focus();
      }
    },
    [],
  );

  useLayoutEffect(() => {
    if (ref && ref.current) {
      ref.current.focus();
    }
  }, []);
}

export function useKeyPress(targetKey: string, func: () => void) {
  const [isKeyPressed, setIsKeyPressed] = useState(false);

  function downHandler(event: KeyboardEvent) {
    if (event.key === targetKey) {
      setIsKeyPressed(true);
    }
  }

  function upHandler(event: KeyboardEvent) {
    if (event.key === targetKey) {
      setIsKeyPressed(false);
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keydown', upHandler);

    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keydown', upHandler);
    };
  }, []);

  useEffect(() => {
    if (isKeyPressed) {
      func();
    }
  }, [isKeyPressed]);

  return isKeyPressed;
}

export function useAssumeControl() {
  const [assumeControl, setAssumeControl] = useState(
    AuthService.getAssumeControl(),
  );

  useEffect(() => {
    function listener() {
      setAssumeControl(AuthService.getAssumeControl());
    }

    window.addEventListener('localStorageChange', listener, false);

    return () => {
      window.removeEventListener('localStorageChange', listener, false);
    };
  });

  return [
    assumeControl,
    AuthService.setAssumeControl,
    AuthService.clearAssumeControl,
  ] as const;
}

export function useClickOutside(
  ref: React.RefObject<any>,
  handler: (event: MouseEvent | TouchEvent) => void,
) {
  useEffect(() => {
    function listener(event: MouseEvent | TouchEvent) {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      handler(event);
    }

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, []);
}

type Action = { type: string; value: any };
function reducer<State>(state: State, action: Action): State {
  console.log('ACTION: ', action.type, 'Value: ', action.value);
  return action.type ? { ...state, [action.type]: action.value } : state;
}

export function useStateReducer<State>(initialState: State) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return [state as State, dispatch] as const;
}

export function useConfirmation() {
  const result = window.confirm('Are you sure you want to delete this item?');

  return [result];
}

const events = new Set();
const onResize = () =>
  events.forEach((e) => {
    if (typeof e === 'function') {
      e();
    }
  });

export function useWindowSize(options = { throttleMs: 100 }) {
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handle = throttle(() => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }, options.throttleMs);

    if (events.size === 0) {
      window.addEventListener('resize', onResize, true);
    }

    events.add(handle);

    return () => {
      events.delete(handle);

      if (events.size === 0) {
        window.removeEventListener('resize', onResize, true);
      }
    };
  }, []);

  return size;
}

type Query = string | json2mq.QueryObject;
function getMatchMedia(query: Query) {
  return window.matchMedia(typeof query === 'string' ? query : json2mq(query));
}
export function useMatchMedia(query: Query) {
  const initialMediaQueryList = getMatchMedia(query);
  const [matches, setMatches] = useState(!!initialMediaQueryList.matches);

  useEffect(() => {
    const mediaQueryList = getMatchMedia(query);
    let active = true;
    const listener = () => {
      if (!active) {
        return;
      }

      setMatches(!!mediaQueryList.matches);
    };

    mediaQueryList.addListener(listener);
    setMatches(!!mediaQueryList.matches);
    (window as any).__MATCH_MEDIA_COUNT__++;

    return () => {
      active = false;
      mediaQueryList.removeListener(listener);
    };
  }, [query]);

  return matches;
}

let id = 0;
export function useAutoId() {
  const idRef = useRef(++id);

  return idRef.current;
}

import React, { Fragment } from 'react';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';

import { Button } from '~/components/shared/inputs';

const NOTIFICATION_QUERY = gql`
  query {
    notifications {
      id
      text
      primaryAction
      secondaryAction
      isActive
      isRead
    }
  }
`;

export type NotificationItemProps = {
  id: string;
  text: string;
  primaryAction: string;
  secondaryAction: string;
};

function NotificationItem({
  text,
  primaryAction,
  secondaryAction,
}: NotificationItemProps) {
  return (
    <div className="py-2 border-b border-solid border-primary">
      <div className="m-4">
        <div>{text}</div>
        <div className="flex justify-around">
          {!!secondaryAction && (
            <Button secondary onClick={() => {}}>
              {secondaryAction}
            </Button>
          )}
          {!!primaryAction && (
            <Button onClick={() => {}}>{primaryAction}</Button>
          )}
        </div>
      </div>
    </div>
  );
}

function NotificationMenu() {
  const { loading, data: notifications } =
    useQuery<NotificationItemProps[]>(NOTIFICATION_QUERY);

  if (loading) {
    return <Fragment>Loading...</Fragment>;
  }

  return notifications && notifications.length > 0 ? (
    <div className="m-0 list-none">
      {notifications.map((notification) => (
        <NotificationItem key={notification.id} {...notification} />
      ))}
    </div>
  ) : (
    <div>No notifications</div>
  );
}

export default NotificationMenu;

import React, { Fragment, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBell,
  faBook,
  faFilm,
  faGamepad,
  faTv,
  faHeadphones,
} from '@fortawesome/free-solid-svg-icons';
import Gravatar from 'react-gravatar';

import { getEmail } from '~/services/localstorage';
import HeaderMenu from './HeaderMenu';
import NotificationMenu from './NotificationMenu';
import { useClickOutside } from '~/components/shared/hooks';
import IconButton, {
  IconButtonColors,
} from '~/components/shared/inputs/IconButton';

function DesktopHeader() {
  const [showAccountPopover, setShowAccountPopover] = useState(false);
  const [notificationMenuOpen, setNotificationMenuOpen] = useState(false);
  const iconRef = useRef<HTMLButtonElement | null>(null);
  const ref = useRef<HTMLDivElement | null>(null);

  useClickOutside(ref, () => setNotificationMenuOpen(false));

  return (
    <header className="header-area p-4 flex items-center bg-primary text-white relative">
      <Gravatar
        className="rounded-full m-0 cursor-pointer"
        email={getEmail()}
        onClick={() => setShowAccountPopover(true)}
      />
      <IconButton
        ref={iconRef}
        a11yLabel="Notifications"
        icon={<FontAwesomeIcon size="lg" icon={faBell} />}
        color={IconButtonColors.Inherit}
        className="ml-4 relative"
        onClick={() => setNotificationMenuOpen((n) => !n)}
      >
        {notificationMenuOpen && (
          <Fragment>
            <div className="max-h-52 h-96 overflow-y-auto" ref={ref}>
              <NotificationMenu />
            </div>
            <div className="absolute bottom-0 left-3 z-30 w-0 h-0 arrow-up" />
          </Fragment>
        )}
      </IconButton>
      <HeaderMenu
        isDrawerOpen={showAccountPopover}
        onCloseDrawer={() => setShowAccountPopover(false)}
      />
      <Link to="/">
        <h2 className="tracking-widest header-title mb-0 ml-4 text-white absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 hidden lg:block">
          MediaMan
        </h2>
      </Link>
      <div className="ml-auto mr-4 icon-grid text-center text-white">
        <Link to="/books">
          <div>
            <FontAwesomeIcon size="2x" icon={faBook} />
            <div>Books</div>
          </div>
        </Link>
        <Link to="/games">
          <div>
            <FontAwesomeIcon size="2x" icon={faGamepad} />
            <div>Games</div>
          </div>
        </Link>
        <Link to="/movies">
          <div>
            <FontAwesomeIcon size="2x" icon={faFilm} />
            <div>Movies</div>
          </div>
        </Link>
        <Link to="/shows">
          <div>
            <FontAwesomeIcon size="2x" icon={faTv} />
            <div>TV Shows</div>
          </div>
        </Link>
        <Link to="/music">
          <div>
            <FontAwesomeIcon size="2x" icon={faHeadphones} />
            <div>Music</div>
          </div>
        </Link>
      </div>
    </header>
  );
}

export default DesktopHeader;

import React from 'react';

import { TextInput, Toggle } from '~/components/shared/inputs';

type Props = {
  title: string;
  rating: string;
  isWatched: boolean;
  isWantWatch: boolean;
  onChange: (value: string | boolean, key: string) => void;
};

function MovieForm({ title, rating, isWatched, isWantWatch, onChange }: Props) {
  return (
    <div>
      <TextInput
        autoFocus
        placeholder="Movie"
        label="Enter Movie"
        value={title}
        type="text"
        onChange={(value) => onChange(value, 'title')}
      />
      <TextInput
        placeholder="Rating"
        label="Enter Rating"
        value={rating}
        type="text"
        onChange={(value) => onChange(value, 'rating')}
      />

      <Toggle
        checked={isWatched}
        onChange={(checked) => onChange(checked, 'isWatched')}
        label="Have you watched this movie?"
      />

      <Toggle
        checked={isWantWatch}
        onChange={(checked) => onChange(checked, 'isWantWatch')}
        label="Do you want to watch this movie?"
      />
    </div>
  );
}

export default MovieForm;

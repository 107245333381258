import { useNavigate } from 'react-router-dom';

import { useApolloClient } from '@apollo/client';
import AuthService from '../../services/auth';

function LogoutPage() {
  const client = useApolloClient();
  const navigate = useNavigate();

  AuthService.logout().then(() => {
    client.clearStore();
    navigate('/login');
  });

  return null;
}

export default LogoutPage;

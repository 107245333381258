import React, { useEffect, useRef, useState } from 'react';

import { ApolloQueryResult, useMutation } from '@apollo/client';
import gql from 'graphql-tag';

import Notification from '~/components/shared/Notification';
import { TextInput } from '~/components/shared/inputs';
import { Button } from '~/components/shared/inputs';
import { importIntegration } from '~/services/integration';
import { useLoaderData } from 'react-router';

enum ImportStatus {
  None,
  Success,
  InFlight,
  Error,
}

type ImportDataType = { total_games: number; games_added: number };

function getNotificationMessage(
  importStatus: ImportStatus,
  importData?: ImportDataType,
): string {
  let message = '';

  switch (importStatus) {
    case ImportStatus.Success: {
      let totalGames,
        gamesAdded = 0;

      if (importData) {
        totalGames = importData.total_games;
        gamesAdded = importData.games_added;
      }

      message = `We added ${gamesAdded} out of ${totalGames} games from steam`;
      break;
    }
    case ImportStatus.InFlight:
      message = `Importing games from steam...`;
      break;
    case ImportStatus.Error:
      message = `There was an error importing games from steam. Check your steam id.`;
      break;
    default:
      message = '';
  }

  return message;
}

const UPDATE_STEAM_MUTATION = gql`
  mutation updateSteam($steamId: ID!) {
    updateSteam(steamId: $steamId) {
      steamId
    }
  }
`;

function Steam() {
  const inputRef = useRef<null | HTMLInputElement>(null);
  const [steamId, setSteamId] = useState('');
  const [importStatus, setImportStatus] = useState(ImportStatus.None);
  const [importData, setImportData] = useState<ImportDataType>();
  const showNotification = importStatus !== ImportStatus.None;
  const notificationMessage = getNotificationMessage(importStatus, importData);
  const { data } = useLoaderData() as ApolloQueryResult<any>;
  const [updateSteamMutation] = useMutation(UPDATE_STEAM_MUTATION);

  function resetImportStatus() {
    setImportStatus(ImportStatus.None);
  }

  function saveSteamId() {
    updateSteamMutation({
      variables: { steamId },
    });
  }

  async function importSteamGames() {
    setImportStatus(ImportStatus.InFlight);
    setImportData(undefined);

    try {
      let importData = await importIntegration('steam');
      importData = importData.error ? {} : importData;

      setImportStatus(ImportStatus.Success);
      setImportData(importData);
    } catch (err) {
      setImportStatus(ImportStatus.Error);
    }
  }

  useEffect(() => {
    if (data && data.integration && data.integration.steamId) {
      setSteamId(data.integration.steamId);
    }
  }, [data]);

  return (
    <div className="p-4">
      <div className="z-50 relative">
        <Notification
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          isOpen={showNotification}
          autoHideDuration={3000}
          message={notificationMessage}
          onClose={resetImportStatus}
        />
      </div>
      <h4 className="m-0 p-0 mb-4">Steam:</h4>
      <div className="flex flex-wrap items-center text-center justify-center">
        <div className="flex-1 flex-shrink-0 md:flex-auto md:w-1/2">
          <TextInput
            ref={inputRef}
            fullWidth
            placeholder="steam id"
            label="Enter steam id"
            value={steamId}
            onChange={setSteamId}
          />
        </div>
        <div className="flex-auto inline-block ml-4">
          <Button fullWidth secondary onClick={saveSteamId}>
            Save
          </Button>
        </div>
        <div className="flex-auto inline-block ml-4">
          <Button fullWidth onClick={importSteamGames}>
            Import
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Steam;

import React, { Fragment, useState } from 'react';

import { ApolloQueryResult, useMutation } from '@apollo/client';
import gql from 'graphql-tag';

import { Button, TextInput } from '~/components/shared/inputs';
import Divider from '~/components/shared/Divider';
import { useIsLoading } from '~/hooks';
import { useLoaderData } from 'react-router';

const SEND_INVITATION_MUTATION = gql`
  mutation sendInvitation($toEmailAddress: String!) {
    sendInvitation(toEmailAddress: $toEmailAddress) {
      id
      toEmailAddress
      isAccepted
    }
  }
`;

const ACCEPT_INVITATION_MUTATION = gql`
  mutation acceptInvitation($id: ID!) {
    acceptInvitation(id: $id) {
      id
      isAccepted
    }
  }
`;

const REJECT_INVITATION_MUTATION = gql`
  mutation rejectInvitation($id: ID!) {
    rejectInvitation(id: $id) {
      id
      isAccepted
    }
  }
`;

interface Invitation {
  id: string;
  isAccepted: boolean;
}

interface SentInvitation extends Invitation {
  toEmailAddress: string;
}
interface ReceivedInvitation extends Invitation {
  fromEmailAddress: string;
}

function Invitations() {
  const [toEmailAddress, setToEmailAddress] = useState('');
  const { data } = useLoaderData() as ApolloQueryResult<{
    sentInvitations: Array<SentInvitation>;
    receivedInvitations: Array<ReceivedInvitation>;
  }>;
  const loading = useIsLoading();
  const [acceptInvitation] = useMutation(ACCEPT_INVITATION_MUTATION);
  const [rejectInvitation] = useMutation(REJECT_INVITATION_MUTATION);
  const [sendInvitation] = useMutation(SEND_INVITATION_MUTATION);

  // function updateRejectInvitation(cache, { data: { rejectInvitation } }) {
  //   removeItemFromQuery(
  //     cache,
  //     INVITATIONS_QUERY,
  //     rejectInvitation,
  //     'sentInvitations',
  //     'receivedInvitations'
  //   );
  // }

  // function updateAcceptInvitation(cache, { data: { acceptInvitation } }) {}

  // function updateSendInvitation(cache, { data: { sendInvitation } }) {
  //   addItemToQuery(cache, INVITATIONS_QUERY, sendInvitation, 'sentInvitations');

  //   setToEmailAddress('');
  // }

  if (loading) return <Fragment>{'Loading...'}</Fragment>;

  let sentInvitations: SentInvitation[] = [],
    receivedInvitations: ReceivedInvitation[] = [];

  if (data) {
    sentInvitations = data.sentInvitations;
    receivedInvitations = data.receivedInvitations;
  }

  const hasSentInvitations = sentInvitations && sentInvitations.length > 0;
  const hasReceivedInvitations =
    receivedInvitations && receivedInvitations.length > 0;

  return (
    <div>
      <div className="flex flex-wrap items-center m-4">
        {hasSentInvitations && (
          <div className="flex-auto flex-shrink-0">
            <h3>Invitations Sent:</h3>
            {sentInvitations.map((invitation) => (
              <div key={invitation.id}>
                <strong>{invitation.toEmailAddress}</strong>
                {invitation.isAccepted ? (
                  'Accepted'
                ) : (
                  <div>
                    <Button
                      onClick={() =>
                        rejectInvitation({
                          variables: { id: invitation.id },
                        })
                      }
                    >
                      Delete Invitation
                    </Button>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
        {hasReceivedInvitations && (
          <div className="flex-auto flex-shrink-0">
            <h3>Invitations Received:</h3>
            {receivedInvitations.map((invitation) => (
              <div key={invitation.id}>
                {invitation.fromEmailAddress}
                {invitation.isAccepted ? (
                  'Yes'
                ) : (
                  <div>
                    <Button
                      onClick={() =>
                        acceptInvitation({
                          variables: { id: invitation.id },
                        })
                      }
                    >
                      Accept Invitation
                    </Button>
                    <Button
                      onClick={() =>
                        rejectInvitation({
                          variables: { id: invitation.id },
                        })
                      }
                    >
                      Reject Invitation
                    </Button>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
      <Divider />
      <div className="flex flex-wrap m-4">
        <h3 className="flex-grow w-full">Send a friend request: </h3>
        <div className="flex-grow w-1/2">
          <TextInput
            fullWidth
            placeholder="email address"
            label="Enter email address"
            value={toEmailAddress}
            onChange={setToEmailAddress}
          />
        </div>
        <div className="flex-auto flex-shrink-0">
          <Button
            onClick={() => {
              sendInvitation({
                variables: { toEmailAddress },
              });
            }}
          >
            Send Invitation
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Invitations;

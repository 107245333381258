import gql from 'graphql-tag';

export const INVITATIONS_QUERY = gql`
  query {
    sentInvitations {
      id
      toEmailAddress
      isAccepted
    }
    receivedInvitations {
      id
      fromEmailAddress
      isAccepted
    }
  }
`;

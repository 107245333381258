import gql from 'graphql-tag';

export const ADD_USER_HANDLE_MUTATION = gql`
  mutation addUserHandle($service: String!, $name: String!) {
    addUserHandle(service: $service, name: $name) {
      id
      service
      name
    }
  }
`;
export const UPDATE_USER_HANDLE_MUTATION = gql`
  mutation updateUserHandle($id: ID!, $service: String!, $name: String!) {
    updateUserHandle(id: $id, service: $service, name: $name) {
      id
      service
      name
    }
  }
`;
export const DELETE_USER_HANDLE_MUTATION = gql`
  mutation deleteUserHandle($id: ID!) {
    deleteUserHandle(id: $id) {
      id
      service
      name
    }
  }
`;

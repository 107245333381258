import React from 'react';
import { KEYS } from '~/utils/keyboard';
import { motion } from 'framer-motion';
import classNames from 'classnames';

type Props = {
  label: string;
  checked: boolean;
  fluid?: boolean;
  onChange: (checked: boolean) => void;
};
function Toggle({ label, checked, fluid = false, onChange }: Props) {
  function toggle() {
    onChange(!checked);
  }

  function handleKeyDown(event: React.KeyboardEvent<HTMLLabelElement>) {
    if (event.key === KEYS.ENTER || event.key === KEYS.SPACE) {
      toggle();
    }
  }

  return (
    <label
      className={classNames(
        'flex relative items-center cursor-pointer py-2 px-0 my-0 mx-2',
        {
          'justify-between': fluid,
        },
      )}
      tabIndex={0}
      onKeyDown={handleKeyDown}
    >
      <div
        className={classNames('w-8 h-4 relative mr-4 bg-gray-300 rounded-lg', {
          'bg-primary': checked,
        })}
      >
        <motion.div
          className={classNames(
            'bg-white absolute w-5 h-5 rounded-full top-1/2 transform -translate-x-1/2 -translate-y-1/2 shadow-inner',
            {
              'bg-primaryFocus': checked,
            },
          )}
          animate={checked ? 'on' : 'off'}
          variants={{
            on: { left: '75%' },
            off: { left: '25%' },
          }}
        />
      </div>
      <input
        tabIndex={-1}
        className="absolute top-0 left-0 right-0 opacity-0"
        type="checkbox"
        value=""
        checked={checked}
        onChange={toggle}
      />
      {label}
    </label>
  );
}

export default Toggle;

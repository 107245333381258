interface ConditionalFunc {
  (): boolean;
}
type Props = {
  condition: ConditionalFunc | boolean;
  element: JSX.Element;
};

function isFunction(item: any): item is ConditionalFunc {
  return typeof item === 'function';
}
function ConditionalRender({ condition, element }: Props) {
  const result = isFunction(condition) ? condition() : condition;

  return result ? element : null;
}

export default ConditionalRender;

import {
  useSearchParams,
  useNavigation,
  useNavigate as useNav,
  NavigateOptions,
} from 'react-router-dom';

export function useIsLoading() {
  const navigation = useNavigation();
  return navigation.state === 'loading';
}

type Opts = {
  preserveQueryParams: boolean;
};
export function useNavigate(
  path: string,
  { preserveQueryParams, ...navigateOptions }: NavigateOptions & Opts,
) {
  const navigate = useNav();
  const newPath = usePath(path, { preserveQueryParams });

  return () => navigate(newPath, navigateOptions);
}

export function usePath(path: string, opts?: Opts) {
  const keepQueryParams = !opts || !!opts.preserveQueryParams;
  const [searchParams] = useSearchParams();
  const newPath = keepQueryParams ? `${path}?${searchParams.toString()}` : path;

  return newPath;
}

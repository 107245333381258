import React from 'react';
import tinycolor from 'tinycolor2';
import LocalStorage, { StorageKeys } from '~/services/localstorage';

export function darken(color: string, percent: number) {
  return tinycolor(color).darken(percent).toString();
}

export function getRandomPrimary() {
  let randomColor = tinycolor.random().toString();

  while (!tinycolor.isReadable(randomColor, '#FFF', {})) {
    randomColor = tinycolor.random().toString();
  }

  return randomColor;
}

function setCssVar(name: string, value: string) {
  if (window) {
    window.document.documentElement.style.setProperty(`--${name}`, value);
  }
}

export interface Theme {
  primary: string;
  lightPrimary: string;
  darkPrimary: string;
  secondary: string;
  accent: string;
  border: string;
  pureWhite: '#fff';
  white: string;
  grey: string;
  black: string;
  error: string;
  [key: string]: string;
}
export function generateTheme(primary?: string): Theme {
  primary = primary || getRandomPrimary();
  const primaryTinyColor = tinycolor(primary);
  const [, border] = primaryTinyColor.monochromatic(10);
  const [, secondary, accent] = primaryTinyColor.splitcomplement();
  const white = tinycolor.mix('#fff', primary, 10);

  return {
    primary,
    lightPrimary: primaryTinyColor.lighten(50).toHexString(),
    darkPrimary: darken(primary, 10),
    secondary: secondary.toHexString(),
    accent: accent.toHexString(),
    border: border.toHexString(),
    pureWhite: '#fff',
    white: white.toHexString(),
    grey: white.greyscale().toHexString(),
    black: tinycolor.mix('#000', primary, 10).toHexString(),
    error: tinycolor.mix('#f00', primary, 10).toHexString(),
  };
}

export const DEFAULT_THEME = {
  primary: getRandomPrimary(),
  white: '#DEDEDE',
};

/**
 * Hooks
 */

export function setThemeVars(theme: Theme) {
  for (const varName in theme) {
    setCssVar(varName, theme[varName]);
  }
}
export function getTheme() {
  const theme: Theme = LocalStorage.has(StorageKeys.Settings)
    ? JSON.parse(LocalStorage.get(StorageKeys.Settings) as string)
    : generateTheme(DEFAULT_THEME.primary);

  // Added after saved themes so must verify this is set
  theme.darkPrimary = theme.darkPrimary || darken(theme.primary, 10);
  return theme;
}
export function initTheme() {
  const theme = getTheme();

  setThemeVars(theme);
}

export function Colors() {
  const theme = getTheme();
  const colorKeys = Object.keys(theme);

  return (
    <div>
      {colorKeys.map((type) => {
        const color = theme[type];
        return (
          <div
            key={type}
            style={{
              background: color,
              color: type === 'white' ? 'black' : 'white',
            }}
          >
            {type}
          </div>
        );
      })}
    </div>
  );
}

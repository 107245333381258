import { client } from './graphql';
import { ADMIN_USERS_QUERY } from './pages/Admin/queries';
import { BOOKS_QUERY } from './pages/Books/queries';
import { FRIENDS_QUERY } from './pages/Friends/queries';
import { INTEGRATION_QUERY } from './pages/Integrations/queries';
import { INVITATIONS_QUERY } from './pages/Invitations/queries';
import { MOVIES_QUERY } from './pages/Movies/queries';
import { GAME_PLATFORMS_QUERY } from './pages/VideoGames/queries';
import { DEFAULT_FILTERS } from './pages/VideoGames/utils';
import { USER_HANDLES_QUERY, USER_PREFERENCES_QUERY } from './queries';

export const invitationsLoader = () =>
  client.query({ query: INVITATIONS_QUERY });
export const friendsLoader = () => client.query({ query: FRIENDS_QUERY });
export const adminUsersLoader = () =>
  client.query({ query: ADMIN_USERS_QUERY });
export const moviesLoader = () => client.query({ query: MOVIES_QUERY });
export const gamePlatformsLoader = () =>
  client.query({ query: GAME_PLATFORMS_QUERY });
export const booksLoader = () => client.query({ query: BOOKS_QUERY });
export const integrationsLoader = () =>
  client.query({ query: INTEGRATION_QUERY });
export const userPreferencesLoader = async () => {
  const {
    data: { userPreferences },
  } = await client.query({ query: USER_PREFERENCES_QUERY });
  const gameFilterUserPreference = userPreferences.find(
    (p: any) => p.name === 'game_filters',
  );

  if (gameFilterUserPreference) {
    return {
      ...gameFilterUserPreference,
      value: JSON.parse(gameFilterUserPreference.value),
    };
  }
  return gameFilterUserPreference ?? { value: DEFAULT_FILTERS };
};
export const settingsLoader = () => {
  return client.query({ query: USER_HANDLES_QUERY });
};

import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

import {
  TextInput,
  Dropdown,
  Autocomplete,
  Toggle,
  Button,
  ButtonGroup,
  IconButton,
  IconButtonColors,
} from '~/components/shared/inputs';

function Styleguide() {
  const [textInputValue, setTextInputValue] = useState('');
  const [dropdownInputValue, setDropdownInputValue] = useState('');
  const [autocompleteInputValue, setAutocompleteInputValue] = useState<
    string | null | undefined
  >('');
  const [_selectedItem, setSelectedItem] = useState<any>(null);
  const [isToggleChecked, setIsToggleChecked] = useState(true);

  return (
    <div>
      <section>
        <h2>Inputs</h2>

        <form>
          <legend>TextField</legend>
          <TextInput
            label="Text label"
            value={textInputValue}
            onChange={setTextInputValue}
          />

          <legend>Dropdown</legend>
          <Dropdown
            label="Dropdown label"
            items={[
              { value: '1', text: 'Value 1' },
              { value: '2', text: 'Value 2' },
              { value: '3', text: 'Value 3' },
              { value: '4', text: 'Value 4' },
              { value: '5', text: 'Value 5' },
            ]}
            selectedValue={dropdownInputValue}
            onChange={setDropdownInputValue}
          />

          <legend>Autocomplete</legend>
          <Autocomplete
            labelKey="text"
            label="Dropdown label"
            items={[
              { value: '1', text: 'Value 1' },
              { value: '2', text: 'Value 2' },
              { value: '3', text: 'Value 3' },
              { value: '4', text: 'Value 4' },
              { value: '5', text: 'Value 5' },
            ]}
            value={autocompleteInputValue || undefined}
            onTextChange={setAutocompleteInputValue}
            onItemSelected={setSelectedItem}
            itemRender={(item) => <div>{item.text}</div>}
          />

          <legend>Toggle</legend>
          <Toggle
            label="Toggle Label"
            checked={isToggleChecked}
            onChange={setIsToggleChecked}
          />

          <legend>Icon Button</legend>
          <IconButton
            a11yLabel="Bars"
            icon={<FontAwesomeIcon icon={faBars} />}
            color={IconButtonColors.Inherit}
            onClick={() => alert('Clicked!')}
          >
            <label>Icon Button</label>
          </IconButton>

          <legend>Button</legend>
          <ButtonGroup activeIndex={0} onChange={() => {}}>
            <Button onClick={() => alert('Clcked!')}>Primary Button</Button>
            <Button secondary onClick={() => alert('Clcked!')}>
              Secondary Button
            </Button>
          </ButtonGroup>
        </form>
      </section>
    </div>
  );
}

export default Styleguide;

import Typography from 'typography';

const typography = new Typography({
  baseFontSize: '16px',
  baseLineHeight: 1.5,
  includeNormalize: true,
  headerFontFamily: ['Gabriela', 'sans-serif'],
  bodyFontFamily: ['Monsterrat', 'serif'],
  googleFonts: [
    {
      name: 'Montserrat',
      styles: ['400', '700'],
    },
    {
      name: 'Gabriela',
      styles: ['400'],
    },
  ],
  overrideStyles: () => {
    return {
      html: {
        overflowY: 'hidden',
      },
      a: {
        textDecoration: 'none',
        color: '#607d8b',
      },
      li: {
        margin: 0,
      },
    };
  },
});

export function injectTypography() {
  typography.injectStyles();
}

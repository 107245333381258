import React from 'react';

import Steam from './components/Steam';

function IntegrationsPage() {
  return (
    <div>
      <Steam />
    </div>
  );
}

export default IntegrationsPage;

import React, { Fragment } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { ApolloQueryResult, useApolloClient } from '@apollo/client';

import { Button } from '~/components/shared/inputs';
import List from '~/components/shared/List';
import RaisedShadow from '~/components/shared/RaisedShadow';
import AuthService from '~/services/auth';
import { useIsLoading } from '~/hooks';
import { User, UserConnection } from '~/types';

type Props = {
  user: User;
};
function UserItem({ user: { id, email } }: Props) {
  const navigate = useNavigate();
  const client = useApolloClient();

  function handleAssumeControl() {
    client.resetStore();
    AuthService.setAssumeControl({ id, email });

    navigate('..');
  }

  return (
    <RaisedShadow>
      <div className="flex items-center">
        <div>
          {id} - {email}
        </div>
        <div className="ml-auto">
          <Button onClick={handleAssumeControl}>Assume Control</Button>
        </div>
      </div>
    </RaisedShadow>
  );
}

function AdminPage() {
  const navigate = useNavigate();
  const isAdmin = AuthService.isAdmin();
  const loading = useIsLoading();
  const { data } = useLoaderData() as ApolloQueryResult<{
    users: UserConnection;
  }>;
  const users = data.users.edges?.map((edge) => edge.node) ?? [];

  if (loading) {
    return <Fragment>Loading...</Fragment>;
  }

  if (!isAdmin) {
    navigate('..');
  }

  return (
    <List
      items={users}
      itemRender={(user) => <UserItem key={user.id} user={user} />}
      noResults={() => null}
    />
  );
}

export default AdminPage;

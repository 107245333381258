import React, { Fragment, useState } from 'react';

import classNames from 'classnames';

type Props = {
  className?: string;
  children: React.ReactNode;
  onKeyPress?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  center: boolean;
};
function Paper({ className, children, onKeyPress, center }: Props) {
  const [height, setHeight] = useState<string | number>('auto');

  function handleRef(ref: HTMLDivElement) {
    if (ref && height !== ref.clientHeight) {
      setHeight(ref.clientHeight);
    }
  }

  return (
    <Fragment>
      <div
        style={{ height }}
        className={classNames(
          'rounded bg-white shadow-inner bg-secondary transform rotate-6',
          className,
          {
            'fixed center': center,
          },
        )}
      />
      <div
        style={{ height }}
        className={classNames(
          'rounded bg-white shadow-inner bg-secondary transform -rotate-6',
          className,
          {
            'fixed center': center,
          },
        )}
      />
      <div
        ref={handleRef}
        className={classNames('rounded bg-white shadow-inner', className, {
          'fixed transform center': center,
        })}
        onKeyPress={onKeyPress}
      >
        {children}
      </div>
    </Fragment>
  );
}

Paper.defaultProps = {
  center: true,
};

export default Paper;

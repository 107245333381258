import React from 'react';
//import { Query } from "react-apollo";
//import gql from "graphql-tag";

import { TextInput, Toggle } from '~/components/shared/inputs';

// const BOOK_LOOKUP_QUERY = gql`
//   query bookLookup($author: String!, $title: String!, $search: String!) {
//     bookLookup(author: $author, title: $title, search: $search) {
//       volumeId
//       isbn
//       title
//       author
//       images {
//         thumbnail
//         smallThumbnail
//       }
//     }
//   }
// `;

type Props = {
  author: string;
  title: string;
  isFinished?: boolean;
  isOwned?: boolean;
  isReading?: boolean;
  isDigital?: boolean;
  onChange: (value: string | boolean, key: string) => void;
};

function BookForm({
  author,
  title,
  isFinished = false,
  isOwned = false,
  isReading = false,
  isDigital = false,
  onChange,
}: Props) {
  return (
    <div>
      <TextInput
        autoFocus
        placeholder="Author"
        label="Enter Author"
        value={author}
        type="text"
        onChange={(value) => onChange(value, 'author')}
      />
      <TextInput
        placeholder="Title"
        label="Enter Title"
        value={title}
        type="text"
        onChange={(value) => onChange(value, 'title')}
      />

      <Toggle
        checked={isFinished}
        onChange={(checked) => onChange(checked, 'isFinished')}
        label="Have you read this book?"
      />

      <Toggle
        checked={isOwned}
        onChange={(checked) => onChange(checked, 'isOwned')}
        label="Do you own this book?"
      />

      <Toggle
        checked={isReading}
        onChange={(checked) => onChange(checked, 'isReading')}
        label="Are you currently reading this book?"
      />

      <Toggle
        checked={isDigital}
        onChange={(checked) => onChange(checked, 'isDigital')}
        label="Is this book digital?"
      />
    </div>
  );
}

export default BookForm;

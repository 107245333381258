import classNames from 'classnames';
import React from 'react';

type Props = {
  className?: string;
  children: JSX.Element;
};

function ChipGroup({ className, children }: Props) {
  return (
    <div className={classNames(className, 'flex flex-wrap gap-y-2 my-2')}>
      {children}
    </div>
  );
}

export default ChipGroup;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';

import { Button } from '~/components/shared/inputs';
import { useAssumeControl } from '~/components/shared/hooks';

function AssumeControlBanner() {
  const navigate = useNavigate();
  const client = useApolloClient();
  const [assumeControl, , clearAssumeControl] = useAssumeControl();

  function handleReturnAdmin() {
    clearAssumeControl();
    client.resetStore();

    navigate('/admin');
  }

  return assumeControl ? (
    <div
      style={{ gridArea: 'admin' }}
      className="px-4 flex flex-wrap items-center"
    >
      <div style={{ flex: '10 0 auto' }}>
        Assuming control of {assumeControl.email}
      </div>
      <div className="ml-auto flex-initial">
        <Button autoWidth onClick={handleReturnAdmin}>
          Return to admin
        </Button>
      </div>
    </div>
  ) : null;
}

export default AssumeControlBanner;

import React, { useMemo } from 'react';
import Downshift from 'downshift';
import classNames from 'classnames';

import ArrowIcon from '~/components/ArrowIcon';

interface Indexer {
  [key: string]: any;
}
type Props<Item extends Indexer> = {
  label: string;
  items: Array<Item>;
  selectedValue: string | null;
  onChange: (selectedValue: string) => void;
  valueKey: string;
  textKey: string;
};
function Dropdown<Item extends Indexer>(props: Props<Item>) {
  const { label, items, selectedValue, onChange, valueKey, textKey } = props;
  const selectedItem = useMemo(
    () => items.find((i) => i[valueKey] === selectedValue),
    [selectedValue],
  );

  function handleChange(itemSelected: any) {
    if (onChange) {
      onChange(itemSelected[valueKey]);
    }
  }

  return (
    <Downshift
      selectedItem={selectedItem}
      onChange={handleChange}
      itemToString={(item) => (item ? item[textKey] : '')}
    >
      {(renderProps) => {
        const {
          getInputProps,
          getToggleButtonProps,
          getItemProps,
          getLabelProps,
          getMenuProps,
          getRootProps,
          isOpen,
          highlightedIndex,
        } = renderProps;

        return (
          <div
            className="border border-solid border-primary relative rounded-sm px-2 pt-2 my-1 mx-auto h-12"
            {...(getRootProps(undefined, { suppressRefError: true }) as any)}
          >
            <label
              {...getLabelProps()}
              className="absolute top-1.5 left-1.5 text-primary font-bold text-xs"
            >
              {label}
            </label>
            <button
              className="appearance-none w-full absolute top-0 left-0 right-0 bottom-0 pr-8 text-left bg-transparent border-0 cursor-pointer text-primary focus:outline-black"
              {...getToggleButtonProps()}
              {...getInputProps()}
            >
              <div className="absolute bottom-0 left-1.5">
                {selectedItem ? selectedItem[textKey] : 'Select One'}
              </div>
              <div className="absolute bottom-0 right-2">
                <ArrowIcon isOpen={isOpen} />
              </div>
            </button>
            <ul
              {...getMenuProps()}
              style={{ marginTop: 'calc(3rem + -1px' }}
              className={classNames(
                'list-none mt-1 p-0 text-left absolute top-0 left-0 right-0 z-10',
                'text-primary overflow-auto max-h-40',
                {
                  'border border-solid border-primary': isOpen,
                },
              )}
            >
              {isOpen
                ? items.map((item, index) => (
                    <li
                      key={item[valueKey]}
                      {...getItemProps({
                        item,
                        index,
                      })}
                      className={classNames('m-0 p-2 cursor-pointer bg-white', {
                        'bg-primary text-white':
                          selectedValue === item[valueKey],
                        'bg-white':
                          highlightedIndex === index &&
                          selectedValue !== item[valueKey],
                      })}
                    >
                      {item[textKey]}
                    </li>
                  ))
                : null}
            </ul>
          </div>
        );
      }}
    </Downshift>
  );
}

Dropdown.defaultProps = {
  valueKey: 'value',
  textKey: 'text',
  inputProps: {},
};

export default Dropdown;

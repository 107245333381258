import React, { useMemo } from 'react';
import {
  UseMultipleSelectionActions,
  UseMultipleSelectionPropGetters,
  UseSelectPropGetters,
  useMultipleSelection,
  useSelect,
} from 'downshift';
import classNames from 'classnames';
import Toggle from './Toggle';

function getItemLabel<T>(item: T, labelKey: keyof T): string {
  return item ? (item[labelKey] as string) : '';
}
function getItemValue<T>(item: T, valueKey: keyof T): string {
  return item[valueKey] as string;
}
function itemFilterFunc<T>(selectedValues: T[keyof T][], valueKey: keyof T) {
  return function itemFilter(item: T) {
    return selectedValues.every((s) => s !== item[valueKey]);
  };
}
function isItemChecked<T>(
  selectedValues: T[keyof T][],
  item: T,
  valueKey: keyof T,
) {
  return selectedValues.some((s) => s === getItemValue(item, valueKey));
}

type SelectedItemsProps<T> = {
  selectedItems: T[];
  getSelectedItemProps: UseMultipleSelectionPropGetters<T>['getSelectedItemProps'];
  removeSelectedItem: UseMultipleSelectionActions<T>['removeSelectedItem'];
  textKey: keyof T;
};
function SelectedItemTags<T>({
  selectedItems,
  getSelectedItemProps,
  removeSelectedItem,
  textKey,
}: SelectedItemsProps<T>) {
  return (
    <>
      {selectedItems.map(function renderSelectedItem(
        selectedItemForRender,
        index,
      ) {
        return (
          <span
            className="bg-primary text-white rounded-md px-1 focus:bg-primaryFocus"
            key={`selected-item-${index}`}
            {...getSelectedItemProps({
              selectedItem: selectedItemForRender,
              index,
            })}
          >
            {selectedItemForRender
              ? getItemLabel(selectedItemForRender, textKey)
              : ''}
            <span
              className="px-1 cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                removeSelectedItem(selectedItemForRender);
              }}
            >
              &#10005;
            </span>
          </span>
        );
      })}
    </>
  );
}

type Props<T> = {
  items: T[];
  label: string;
  textKey: keyof T;
  valueKey: keyof T;
  selectedValues: T[keyof T][];
  onChange: (selectedItems: T[]) => void;
};
function MultiSelect<T>({
  selectedValues,
  items,
  label,
  textKey,
  valueKey,
  onChange,
}: Props<T>) {
  const { getDropdownProps, addSelectedItem, removeSelectedItem } =
    useMultipleSelection<T>({
      selectedItems: items.filter((i) =>
        selectedValues.some((s) => s === i[valueKey]),
      ),
      onSelectedItemsChange: (changes) => {
        if (changes.selectedItems) {
          console.log({ selectedItems: changes.selectedItems });
          onChange(changes.selectedItems);
        }
      },
    });
  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    selectedItem: null,
    defaultHighlightedIndex: 0,
    items,
    stateReducer: (state, { changes, type }) => {
      switch (type) {
        case useSelect.stateChangeTypes.ToggleButtonKeyDownEnter:
        case useSelect.stateChangeTypes.ToggleButtonKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ItemClick: {
          return {
            ...changes,
            isOpen: true,
            highlightedIndex: 0,
          };
        }
      }

      return changes;
    },
    onStateChange: ({ type, selectedItem: newSelectedItem }) => {
      switch (type) {
        case useSelect.stateChangeTypes.ToggleButtonKeyDownEnter:
        case useSelect.stateChangeTypes.ToggleButtonKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ItemClick:
        case useSelect.stateChangeTypes.ToggleButtonBlur:
          if (newSelectedItem) {
            const isChecked = isItemChecked(
              selectedValues,
              newSelectedItem,
              valueKey,
            );
            if (isChecked) {
              removeSelectedItem(newSelectedItem);
            } else {
              addSelectedItem(newSelectedItem);
            }
          }
          break;
        default:
          break;
      }
    },
  });
  const textNode =
    selectedValues.length > 0
      ? `${selectedValues.length} selected`
      : `Select ${label} \u2193`;

  return (
    <div className="relative">
      <div className="flex flex-col gap-1 relative">
        <label
          {...getLabelProps()}
          className="absolute -top-2 text-primary font-bold text-xs"
        >
          {label}
        </label>
        <div className="shadow-sm bg-white inline-flex gap-2 items-center flex-wrap p-1.5 border-2 mt-2 focus-within:border-gray-400">
          {/* <SelectedItemTags
            selectedItems={selectedItems}
            getSelectedItemProps={getSelectedItemProps}
            removeSelectedItem={removeSelectedItem}
            textKey={textKey}
          /> */}
          <div
            className="w-full bg-transparent border-0 cursor-pointer text-primary focus:outline-black"
            {...getToggleButtonProps(
              getDropdownProps({ preventKeyAction: isOpen }),
            )}
          >
            {textNode}
          </div>
        </div>
      </div>
      <ul
        className={`absolute w-full bg-white mt-1 shadow-md max-h-80 overflow-scroll p-0 z-10 ${
          !(isOpen && items.length) && 'hidden'
        }`}
        {...getMenuProps()}
      >
        {isOpen &&
          items.map((item, index) => (
            <SelectItem<T>
              key={index}
              item={item}
              index={index}
              textKey={textKey}
              valueKey={valueKey}
              getItemProps={getItemProps}
              highlightedIndex={highlightedIndex}
              selectedValues={selectedValues}
            />
          ))}
      </ul>
    </div>
  );
}

function SelectItem<T>({
  item,
  index,
  getItemProps,
  textKey,
  selectedValues,
  valueKey,
}: ListItemProps<T>) {
  const isChecked = isItemChecked(selectedValues, item, valueKey);
  const label = getItemLabel(item, textKey);
  const handleChange = () => {};
  return (
    <li {...getItemProps({ item, index })}>
      <Toggle checked={isChecked} label={label} onChange={handleChange} />
    </li>
  );
}

type ListItemProps<T> = {
  item: T;
  textKey: keyof T;
  index: number;
  highlightedIndex: number;
  selectedValues: T[keyof T][];
  valueKey: keyof T;
  getItemProps: UseSelectPropGetters<T>['getItemProps'];
};

export default MultiSelect;

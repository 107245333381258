import React, { Fragment } from 'react';

import classNames from 'classnames';

interface ListItemId {
  id: string;
}

function getTotal<Item>(items: Array<Item> | { [key: string]: any }): number {
  if (items) {
    if (Array.isArray(items)) {
      return items.length;
    } else if (typeof items === 'object') {
      return Object.keys(items).length;
    }
  }

  return 0;
}

type StyledListProps = {
  children: any;
  className?: string;
};
export function StyledList({ children, className, ...rest }: StyledListProps) {
  return (
    <ul {...rest} className={classNames('m-0 list-none', className)}>
      {children}
    </ul>
  );
}

type StyledListItemProps = {
  children: any;
  className?: string;
  onClick?: () => void;
};
export function StyledListItem({
  children,
  className,
  ...rest
}: StyledListItemProps) {
  return (
    <li {...rest} className={classNames('py-2 px-4', className)}>
      {children}
    </li>
  );
}

function SimpleList<Item extends ListItemId>({
  items,
  itemRender,
  extraStyles,
}: {
  items: Array<Item>;
  itemRender: (item: Item, index: number) => React.ReactNode;
  extraStyles?: string;
}) {
  return (
    <StyledList className={extraStyles}>
      {items.map((item: Item, index: number) => (
        <StyledListItem key={item.id}>{itemRender(item, index)}</StyledListItem>
      ))}
    </StyledList>
  );
}

function isItemsObject<Item>(
  items: Array<Item> | { [key: string]: ItemsObjectDataType<Item> },
): items is { [key: string]: ItemsObjectDataType<Item> } {
  const castedItems = items as { [key: string]: ItemsObjectDataType<Item> };

  return !Array.isArray(castedItems) && typeof castedItems === 'object';
}

function isObjectDataArray<Item>(
  item: ItemsObjectDataType<Item>,
): item is Array<Item> {
  return Array.isArray(item);
}

type ItemsObjectDataType<Item> =
  | { [dataKey: string]: Array<Item>; meta?: any }
  | Array<Item>;
interface ItemGroupRender {
  (
    itemKey: string,
    index: number,
    dataLength: number,
    meta: { totalCompleted: number },
    styles: string,
  ): React.ReactNode;
}
type ListProps<Item> = {
  items: Array<Item> | { [key: string]: ItemsObjectDataType<Item> };
  itemRender: (item: Item, index: number) => React.ReactNode;
  itemGroupRender?: ItemGroupRender;
  dataKey?: string;
  metaKey?: string;
  noResults?: () => React.ReactNode;
  className?: string;
};

function List<Item extends ListItemId>(props: ListProps<Item>) {
  const {
    items,
    itemRender,
    itemGroupRender = () => null,
    dataKey,
    metaKey,
    noResults,
    className,
  } = props;
  const totalItems = getTotal(items);
  const hasItems = totalItems > 0;

  if (!hasItems) {
    return noResults ? <Fragment>{noResults()}</Fragment> : null;
  } else if (isItemsObject(items)) {
    return (
      <ul className={classNames('list-none m-0', className)}>
        {Object.keys(items).map((itemKey: string, index: number) => {
          const item = items[itemKey];
          const data = isObjectDataArray(item)
            ? item
            : dataKey
            ? item[dataKey]
            : [];
          const meta = metaKey ? (item as any)[metaKey] : {};

          return (
            <li key={itemKey}>
              {itemGroupRender(
                itemKey,
                index,
                data.length,
                meta,
                'sticky z-10 top-10 text-black text-opacity-50 bg-background flex py-3 px-4 font-bold text-sm',
              )}
              <SimpleList items={data} itemRender={itemRender} />
            </li>
          );
        })}
      </ul>
    );
  } else if (Array.isArray(items)) {
    return (
      <SimpleList
        items={items}
        itemRender={itemRender}
        extraStyles={className}
      />
    );
  } else {
    return null;
  }
}

export default List;

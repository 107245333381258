import classNames from 'classnames';
import React from 'react';

type Props = {
  onChange: (newIndex: number) => void;
  activeIndex: number;
  children: React.ReactNode;
};
function ButtonGroup({ activeIndex, onChange, children }: Props) {
  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    const target = e.target as any;
    const dataIndex = target.getAttribute('data-index');
    onChange(Number(dataIndex));
  };
  const className = classNames({
    'rounded-sm': true,
    'mx-2': true,
    'btn-group': true,
    '[&>button:nth-child(1)]:btn-group-item-active': activeIndex === 0,
    '[&>button:nth-child(2)]:btn-group-item-active': activeIndex === 1,
    '[&>button:nth-child(3)]:btn-group-item-active': activeIndex === 2,
  });
  return (
    <div onClick={handleClick} className={className}>
      {children}
    </div>
  );
}

export default ButtonGroup;

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

import RaisedShadow from '~/components/shared/RaisedShadow';
import type { Movie } from './types';

type Props = {
  movie: Movie;
  updateMoviePath: string;
};

function MovieItem({ movie, updateMoviePath }: Props) {
  const { id, title, rating, isWatched } = movie;
  const parsedRating = rating ? `(${parseFloat(rating)})` : '';

  return (
    <div className="flex-1 flex-shrink-0 max-w-full">
      <Link
        to={{
          pathname: updateMoviePath.replace(':id', id),
        }}
        state={{ movie }}
      >
        <RaisedShadow>
          <div>
            {title} {parsedRating}
          </div>
          {isWatched && (
            <div className="absolute top-2 right-2 text-primary">
              <FontAwesomeIcon size="lg" icon={faCheckCircle} />
            </div>
          )}
        </RaisedShadow>
      </Link>
    </div>
  );
}

export default MovieItem;

import React from 'react';
import ReactDOM from 'react-dom';
import { motion, AnimatePresence } from 'framer-motion';

import Overlay from './Overlay';
import { useClickOutside } from './shared/hooks';
import noop from 'lodash-es/noop';

type Props = {
  anchor: 'left' | 'right';
  isOpen: boolean;
  children: React.ReactNode;
  onClose: () => void;
};

export const DrawerContext = React.createContext(noop);

export function DrawerContextValue({
  children,
}: {
  children: (onClose: () => void) => React.ReactNode;
}) {
  const onClose = React.useContext(DrawerContext);

  return <React.Fragment>{children(onClose)}</React.Fragment>;
}

function Drawer({ anchor, isOpen, children, onClose }: Props) {
  const drawerRef = React.useRef(null);
  const [isContentVisible, setIsContentVisible] = React.useState(isOpen);

  React.useEffect(() => {
    if (isOpen && !isContentVisible) {
      setIsContentVisible(true);
    }
  }, [isOpen]);

  const handleClose = React.useCallback(() => {
    setIsContentVisible(false);
  }, []);

  useClickOutside(drawerRef, handleClose);

  return (
    <DrawerContext.Provider value={handleClose}>
      {ReactDOM.createPortal(
        <div className="z-20 relative">
          {isOpen && (
            <Overlay showOnMobile>
              <AnimatePresence onExitComplete={onClose}>
                {isContentVisible && (
                  <motion.div
                    ref={drawerRef}
                    className="fixed bg-white flex flex-col overflow-y-auto h-full outline-none"
                    initial={anchor === 'left' ? 'hiddenLeft' : 'hiddenRight'}
                    variants={{
                      hiddenLeft: { opacity: 0, left: '-20%' },
                      visibleLeft: { opacity: 1, left: 0 },
                      hiddenRight: { opacity: 0, right: '20%' },
                      visibleRight: { opacity: 1, right: 0 },
                    }}
                    animate={anchor === 'left' ? 'visibleLeft' : 'visibleRight'}
                    exit={anchor === 'left' ? 'hiddenLeft' : 'hiddenRight'}
                    transition={{ duration: 0.2, type: 'tween' }}
                  >
                    {children}
                  </motion.div>
                )}
              </AnimatePresence>
            </Overlay>
          )}
        </div>,
        document.body,
      )}
    </DrawerContext.Provider>
  );
}

export default Drawer;

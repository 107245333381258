import React from 'react';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import { useStateReducer } from '~/components/shared/hooks';
import Modal from '~/components/modal/Modal';
import BookForm from './BookForm';
import type { Book } from './types';

type Props = {
  onClose: () => void;
  onDelete: (bookId: string) => void;
  updateBook: (book: Book) => void;
};

function EditBook({ onClose, onDelete, updateBook }: Props) {
  const location = useLocation();
  const book = (location.state as any).book;
  const [state, dispatch] = useStateReducer(book);

  return (
    <Modal
      isOpen
      onClose={onClose}
      title="Update Book"
      headerAction={{
        content: <FontAwesomeIcon size="lg" icon={faTrashAlt} />,
        onClick: () => onDelete(book.id),
      }}
      primaryAction={{
        content: 'Update',
        onClick: () => updateBook(state),
      }}
    >
      <BookForm
        {...state}
        onChange={(value, key) => dispatch({ type: key, value })}
      />
    </Modal>
  );
}

export default EditBook;
